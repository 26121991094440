import { Suspense, useContext } from 'react';

import { OfficePage } from './OfficePage';
import { PartnersContext } from '../hooks/PartnersContext';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import LoadingFallback from './SkeletonLoaders/LoadingFallback';

const useBackButton = (pageTitle: string = "", backTo: string = "") => {
  // @ts-ignore
  const { downlineUid }: number = useContext(PartnersContext);
  // @ts-ignore
  const { userInfo }: number = useAuth();
  const navigate: NavigateFunction = useNavigate();
  const backToSignedInUser: boolean = downlineUid === userInfo.unicityId;

  const handleBackArrowClick = () => {
    if (pageTitle === "search_results") {
      if (backToSignedInUser) {
        navigate("/partners", { replace: true })
      } else {
        navigate(`/partners/${downlineUid}`, { replace: true })
      }
      return;
    }

    navigate(backTo, { replace: true })
  }

  return {
    handleBackArrowClick
  }
}

// HOC to wrap lazy components with Suspense
export const withSuspense = (WrappedComponent) => (props) => {
  const { pageTitle } = props;
  return (
    <Suspense fallback={<LoadingFallback pageTitle={pageTitle} />}>
      <WrappedComponent {...props} />
    </Suspense>
  )
}

// HOC to set the displayName of a lazy component
export const withDisplayName = (name: string, lazyComponent: React.FC): React.FC => {
  lazyComponent.displayName = name;
  return lazyComponent;
};

// HOC to wrap Route components
export const pagesWithSuspense = (WrappedComponent) => (props) => {
  // Deconstruct OfficePage props
  const {
    pageTitle,
    isWebView = false,
    backTo = "",
    showBackArrow = false,
    ...rest
  } = props

  const { handleBackArrowClick } = useBackButton(pageTitle, backTo);

  return (
    <OfficePage 
      pageTitle={pageTitle} 
      showBackArrow={showBackArrow} 
      onBackArrowClick={handleBackArrowClick} 
      isWebView={isWebView}
    >
      <Suspense fallback={<LoadingFallback pageTitle={pageTitle} />}>
        <WrappedComponent {...rest} />
      </Suspense>
    </OfficePage>
  )
}; 
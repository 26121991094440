import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Container, Stack, Typography, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet-async";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { MainNavDrawer } from "./nav/MainNavDrawer";
import { MobileNavIcon } from "./nav/MobileNavIcon";
import { BackArrowButton } from "./BackArrowButton";
import { ActivityFeedButton } from "./activityFeed/ActivityFeedButton";
import { SettingsMenu } from "./settings/SettingsMenu";
import { Drawers } from "./Drawers";
import { GlobalNoticeComponent } from "./GlobalNoticeComponent";
import { CenturionMonthSelect } from "../screens/CenturionScreen/CenturionMonthSelect";

import { useUshopRedirectMarkets } from "../hooks/useUshopRedirectMarkets";
import { LanguageContext } from "../hooks/LanguageContext";
import { ThemeContext } from "../hooks/ThemeContext";
import { T } from "../hooks/useTranslate";
import { useTranslateKey } from "../hooks/useTranslate";
import PopupModal from "./PopupModal";
import { usePopupContext } from "../hooks/PopupContext";

const buttonStyling = {
  border: "1px solid rgba(145, 158, 171, 0.1)",
  boxShadow: "0px 1px 3px rgba(145, 158, 171, 0.1)",
  borderRadius: "8px",
  padding: "8px 10.5px 8px 10.5px"
};

const OfficePage = ({
  children,
  pageTitle,
  showBackArrow,
  onBackArrowClick,
  isWebView = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isPopupActive, isPopupInCardForm, popupData, handleClosePopup } = usePopupContext()
  const theme = useTheme();
  const { shopLink } = useUshopRedirectMarkets();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const { setCurrentLanguage } = useContext(LanguageContext);
  const { mode, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("lang")) {
      setCurrentLanguage(params.get("lang"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const showBackArrowList = [
    "new_feel_great_members",
    "order_reminders",
    "potential_distributors",
    "partials",
    "unicity_news",
    "search_results",
    "statements"
  ];

  const handlePartnersClick = () => {
    if (pageTitle === "partners") {
      navigate("/partners");
    }
  };

  const translatedPageTitle = useTranslateKey(pageTitle);

  return (
    <Box sx={{ display: "flex" }}>
      {!isWebView && (viewportIsSmall || viewportIsMedium)
        && <MainNavDrawer
          shopLink={shopLink}
          viewportIsLarge={viewportIsLarge}
        />
      }
      <Container
        maxWidth={false}
        sx={{
          margin: viewportIsLarge && "0px 16px 0px 16px",
          padding: viewportIsLarge && "0px 0px",
        }}
      >
        <Helmet>{<title>{translatedPageTitle} | Unicity Office</title>}</Helmet>
        {!isWebView && (
          <Box
            className="flex-row"
            style={{
              padding: viewportIsSmall ? "19.5px 0px" : "11.25px 16px",
              gap: "12px"
            }}
          >
            <Box style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              {!viewportIsSmall && !showBackArrowList.includes(pageTitle) && (
                <MobileNavIcon shopLink={shopLink} />
              )}
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "12.5px" }}
              >
                {showBackArrowList.includes(pageTitle) &&
                  showBackArrow && <BackArrowButton pageTitle={pageTitle} onBackArrowClick={onBackArrowClick} />}
                <Typography
                  data-test-id="page-title"
                  onClick={handlePartnersClick}
                  sx={{
                    fontSize: !viewportIsSmall ? "18px" : "20px",
                    fontWeight: "600",
                    cursor: pageTitle === "partners" && "pointer",
                  }}
                >
                  <T>{pageTitle}</T>
                </Typography>
                {viewportIsLarge && pageTitle === "dashboard" && <GlobalNoticeComponent />}
              </Box>
              {pageTitle === "centurion" && viewportIsSmall && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CenturionMonthSelect isDashboard={false} />
                </Box>
              )}
            </Box>
            <Stack direction="row" spacing={1.5} flexShrink={0}>
              <ActivityFeedButton />
              <IconButton
                onClick={toggleTheme}
                sx={buttonStyling}
                data-test-id="theme-toggle-button"
                aria-label={mode === 'dark' ? 'switch to light mode' : 'switch to dark mode'}
              >
                {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              <SettingsMenu />
            </Stack>
          </Box>
        )}
        <Box>
          {pageTitle === "centurion" && !viewportIsSmall && (
            <CenturionMonthSelect />
          )}
        </Box>
        <Box sx={{ marginBottom: viewportIsSmall && "30px" }}>{children}</Box>
        <Drawers />
        {
          !location.pathname.includes("login") && (
            <PopupModal
              open={isPopupActive && !isPopupInCardForm}
              data={popupData}
              onClose={handleClosePopup}
            />
          )
        }
      </Container>
    </Box>
  );
};

export { OfficePage };

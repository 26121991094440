import { Box, Skeleton, Stack, useTheme, useMediaQuery } from '@mui/material';

const LoginScreenSkeleton = () => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack sx={{ height: '100vh', boxSizing: 'border-box' }}>
      {/* Language Button */}
      <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
        <Skeleton variant="rectangular" width={100} height={40} sx={{ borderRadius: "4px" }} />
      </Box>

      {/* Main Content */}
      <Skeleton 
        variant="rectangular" 
        width={viewportIsSmall ? 360 : "90vw"}
        height={350}
        sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: "12px",
        }} 
      />

      {/* Copyright Section */}
      <Box sx={{ 
          position: 
          'absolute', 
          bottom: 0, 
          left: 0, 
          p: 2
        }}
      >
        <Skeleton 
          variant="text" 
          width={200} 
          height={80} 
          sx={{ 
            borderRadius: "4px" 
          }} 
          />
      </Box>
    </Stack>
  );
};

export { LoginScreenSkeleton }; 
import React from 'react';
import { Stack, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ReferralLinksSkeleton: React.FC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const getMainHeight = () => {
    if (isXs) return 300;
    if (isSm) return 350;
    if (isMd) return 380;
    return 400;
  };

  const getSideHeight = () => {
    if (isXs) return 200;
    if (isSm) return 250;
    if (isMd) return 280;
    return 300;
  };

  return (
    <Stack
      direction={isLg ? 'row' : 'column'}
      gap={isXs ? 2 : 3}
      alignItems={isLg ? 'flex-start' : 'stretch'}
      sx={{
        boxSizing: 'border-box',
        px: { xs: 2, sm: 2, md: 3, lg: 0 },
        width: '100%'
      }}
    >
      {/* Main content skeleton */}
      <Skeleton
        variant="rectangular"
        height={getMainHeight()}
        sx={{
          flex: 3,
          borderRadius: "12px",
          boxSizing: 'border-box',
        }}
      />

      {/* Custom links section skeleton */}
      <Skeleton
        variant="rectangular"
        height={getSideHeight()}
        sx={{
          flex: 1,
          borderRadius: "12px",
          minWidth: isLg ? '300px' : 0,
          boxSizing: 'border-box',
        }}
      />
    </Stack>
  );
};

export default ReferralLinksSkeleton; 
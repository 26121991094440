import { Stack, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ActionHubItemLoader = (): JSX.Element => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack 
      spacing={2} 
      sx={{ 
        width: '100%',
        px: { xs: 0, sm: 2 },
        boxSizing: "border-box"
      }}
    >
      {/* Main Content Box */}
      <Skeleton 
        variant="rectangular"
        height={viewportIsSmall ? 600 : 400}
        sx={{ 
          width: '100%',
          borderRadius: { xs: 0, sm: 1 }
        }}
        data-test-id="actionhub-item-table-skeleton"
      />
    </Stack>
  );
};

export default ActionHubItemLoader; 
import React, { useRef, useEffect, useContext } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";
import { useMixPanel } from "../../hooks/useMixPanel";
import {
  Box,
  Stack,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import { useAuth } from "../../hooks/useAuth.js";
import { LanguageContext } from "../../hooks/LanguageContext";
import { ActionableIndicator } from "../../components/ActionableIndicator";
import { useActionableItemsContext } from "../../hooks/ActionableItemsContext";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ExternalIcon } from '../../assets/icons/external.svg';
import { countriesWithNewLibrary } from "./nav-drawer-items.js";
import { marketsWithNewLibrary } from "../../common/marketExceptions.js";
import { useLocation } from "react-router-dom";

const ResourceNavItem = ({
  open,
  item,
  drawerOpen,
  isMobile,
  setDrawerWidth,
  shopLink
}) => {
  const elementRef = useRef(null);
  const { userInfo } = useAuth();
  const translatedItemTitle = useTranslateKey(item.text);
  const { logEvent } = useMixPanel();
  const { languageDir } = useContext(LanguageContext);
  const { isActionable } = useActionableItemsContext();
  const theme = useTheme()
  const navigate = useNavigate();
  const location = useLocation();

  // widen the drawer if the text box breaks the 102 threshold
  useEffect(() => {
    if (elementRef.current) {
      const width = elementRef.current.getBoundingClientRect().width;
      if (width > 105) {
        setDrawerWidth(width + 150)
      }
    }
    // eslint-disable-next-line
  }, []);

  const distributorHelpCenterUrl = useTranslateKey("distributor_help_center_url");

  const getResourceHref = (value) => {
    switch (value) {
      case "external-help":
        return distributorHelpCenterUrl;
      case "shop-unicity":
        return shopLink;
      case "shop-feel-great":
        return item.externalUrl;
      case "old-library":
        // old library auto login
        return `${item.externalUrl}/${btoa(userInfo.token)}`;
      default:
        return "";
    }
  };

  // Add beta tag to new library if supported
  if (item.value === "new-library" && marketsWithNewLibrary.includes(userInfo.countryCode)) {
    item.tags = ["beta"];
  }

  const handleNavClick = () => {
    const status = userInfo.status;
    logEvent(item.eventName, {
      location: "menu",
      status,
      id: userInfo.unicityId
    });

    if (status === "Suspended" || status === "Terminated") {
      localStorage.setItem('showSuspendedTerminatedError', true)
      navigate("/login");
      return
    }

    // Handle new library navigation
    if (item.value === "new-library" && marketsWithNewLibrary.includes(userInfo.countryCode)) {
      navigate(item.url);
      return;
    }

    const resourceHref = getResourceHref(item.value);
    if (!resourceHref) return false;
    window.open(resourceHref, "_blank");
  }

  const commonProps = {
    target: "_blank",
    rel: "noreferrer",
    sx: { textDecoration: "none" },
  };

  const isSelected = () => {
    if (item.value === "new-library" && location.pathname.includes("/library")) {
      return true;
    }
    return false;
  }

  const renderExternalIcon = () => {
    if (item.value === "new-library" && countriesWithNewLibrary.includes(userInfo.countryCode)) {
      return null;
    }

    return (
      <Box>
        <ExternalIcon
          className="external-icon"
          style={{ transform: languageDir === "rtl" ? "rotateY(180deg)" : "none" }}
        />
      </Box>
    )
  }

  return (
    <Link
      {...commonProps}
      onClick={handleNavClick}
      data-test-id={`${item.value}-nav-item-link-element`}
      className="resource-nav-item-link"
    >
      <ListItem
        id={`${item.value}-nav-item`}
        data-test-id={`${item.value}-nav-item`}
        title={translatedItemTitle}
        aria-label={item.text}
        value={item.value}
        disablePadding
        sx={{ display: "block" }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isMobile ? "initial" : "center",
            px: isMobile ? 1.2 : 2.5,
            mb: isMobile ? "0px" : "10px",
          }}
          selected={isSelected()}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isMobile ? (drawerOpen ? 3 : "auto") : open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            <ActionableIndicator
              padRight={-6}
              invisible={!(isActionable(item.text) && !(isMobile ? (!!drawerOpen) : !!open))}>
              {getIcon(item.value, isSelected())}
            </ActionableIndicator>
          </ListItemIcon>
          <ListItemText
            primary={
              <ActionableIndicator invisible={!isActionable(item.text)}>
                <Box
                  sx={{ display: "flex", gap: "4px", alignItems: "flex-start" }}>
                  <Stack flexDirection="row" gap={.5} ref={elementRef}>
                    <T>{item.text}</T>
                    {(item.tags.includes("beta") && countriesWithNewLibrary.includes(userInfo.countryCode)) && (
                      <Stack
                        justifyContent="center"
                        sx={{
                          fontSize: "8px",
                          padding: "2px 6px",
                          color: theme.subscription.subscribed.color,
                          backgroundColor: theme.subscription.subscribed.backgroundColor,
                          borderRadius: "3px",
                        }}
                      >
                        <T>beta</T>
                      </Stack>
                    )}
                  </Stack>
                  {renderExternalIcon()}
                </Box>
              </ActionableIndicator>
            }
            primaryTypographyProps={{
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "12px",
              color: isSelected() ? "#009ECF" : null,
            }}
            sx={{ opacity: isMobile ? (drawerOpen ? 1 : 0) : open ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

const getIcon = (val, selected) => {
  switch (val) {
    case "old-library":
      return <Box sx={{ display: "inline" }}><img src="icons/library-icon-old.svg" alt="library icon" /></Box>;
    case "external-help":
      return <Box sx={{ display: "inline" }}><img src="icons/external-help.svg" alt="help icon" /></Box>;
    case "new-library":
      return <Box sx={{ display: "inline" }}><img src={`icons/library-icon${selected ? "-selected" : ""}.svg`} alt="new library icon" /></Box>;
    case "shop-feel-great":
      return <Box sx={{ display: "inline" }}><img src="icons/shop-feel-great.svg" alt="shop feel great icon" /></Box>;
    case "shop-unicity":
      return <Box sx={{ display: "inline" }}><img src="icons/shop-unicity.svg" alt="shop unicity icon" /></Box>;
    default:
      throw new Error("Icon not found.");
  }
};

export { ResourceNavItem };

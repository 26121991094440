import { Box, Skeleton, Stack, useTheme, useMediaQuery } from '@mui/material';
import { OfficeBox } from '../OfficeBox';

export const CenturionSkeleton = () => {
  const theme = useTheme();
  const viewportIsTabletAndBelow = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ width: '100%' }} data-test-id="centurion-skeleton">
      <Stack spacing={'18px'} direction={viewportIsTabletAndBelow ? 'column' : 'row'}>
        <OfficeBox 
          sx={{ flex: '1 1 100%', boxSizing: 'border-box', borderRadius: '12px' }}
          innerRef={null}
        >
          <Skeleton 
            variant="rectangular" 
            width="100%" 
            height="100%" 
            sx={{ minHeight: '600px', borderRadius: '12px' }}
          />
        </OfficeBox>

        {/* Data grid column */}
        <OfficeBox 
          sx={{ 
            flex: '1 1 100%', 
            boxSizing: 'border-box',
            ...(viewportIsTabletAndBelow && { marginTop: '24px' }),
            borderRadius: '12px'
          }}
          innerRef={null}
        >
          <Box sx={{ padding: '24px' }}>
            <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
            <Stack spacing={1.5}>
              {Array.from({ length: 8 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width="100%"
                  height={48}
                  sx={{ borderRadius: '4px' }}
                />
              ))}
            </Stack>
            
            {/* Pagination skeleton */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Skeleton variant="rounded" width={200} height={36} />
            </Box>
          </Box>
        </OfficeBox>
      </Stack>

      {/* Explained button skeleton */}
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '40px' }}>
        <Skeleton sx={{ borderRadius: '4px' }} variant="rounded" width={200} height={48} />
      </Box>
    </Box>
  );
}; 
import React from 'react';
import { Link } from '@mui/material';

const ComButton = ({ href, dataTestid, children, onClick, disabled = false }) => {

  const handleClick = event => {
    !disabled ? onClick(): event.preventDefault();
  }

  return (
    <Link
      className="com-button"
      href={!disabled ? href: undefined}
      onClick={(event) => handleClick(event)}
      aria-disabled={disabled}
      disabled={disabled}
      data-testid={dataTestid}
    >
      {children}
  </Link>
  );
};

export { ComButton };

import { useState } from "react";
import { T } from "../hooks/useTranslate";
import { useMixPanel } from "../hooks/useMixPanel";
import styled from "styled-components";

import { Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "@mui/material";

const StyledLink = styled(Link)(({ theme, disabled }) => ({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    cursor: disabled ? 'not-allowed' : 'pointer'
  }
}));


const CopyToClipboard = ({
  text = '',
  stringToCopy,
  iconId,
  sx = false,
  eventName,
  eventLocation,
  sendEvent = false,
  userType,
  userId = null,
  children,
  disabled = false
}) => {
  //Tooltip displayed when copying string to clipboard
  const [toolTipIsOpen, setToolTipIsOpen] = useState(false);

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const { logEvent } = useMixPanel();

  //Copy the given value to the clipboard and display a success tooltip
  const copyToClipboard = async (value) => {
    if (disabled) return;
    if (sendEvent) {
      logEvent(eventName, {
        location: eventLocation,
        user_type: userType,
        target_id: userId
      })
    }
    await navigator.clipboard.writeText(value);
    setToolTipIsOpen(true);
    setTimeout(() => setToolTipIsOpen(false), 2000);
  };

  return (
    <Tooltip
      open={toolTipIsOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={<T param1={stringToCopy}>copied_to_clipboard</T>}
      placement={viewportIsSmall ? "right" : "bottom-end"}
      arrow
    >
      <StyledLink onClick={() => copyToClipboard(stringToCopy)}>
        {
          children
            ? children
            : (
              <>
                {text}
                <ContentCopyIcon
                  className="content-copy-icon"
                  sx={{ width: 14, height: 14, marginLeft: "5px", cursor: "pointer", verticalAlign: "middle" }}
                />
              </>
            )
        }
      </StyledLink>
    </Tooltip>
  );
};

export { CopyToClipboard };

import React, { useState, forwardRef } from "react";
import { T } from "../hooks/useTranslate";
import { Tooltip, Stack, Typography, ClickAwayListener } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as NoticeIcon } from '../assets/icons/notice-icon.svg';

const GeneralAlert = ({ alertKey, tooltipKey, hasMargin = true }) => {

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const [open, setOpen] = useState(false);
  const hasTooltip = !!tooltipKey

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event) => {
    setOpen(true);
  };

  const Alert = forwardRef((props, ref) => (
    <Stack
      ref={ref}
      className="notice-stack"
      flexDirection="row"
      alignItems="center"
      gap={1}
      p={1.5}
      m={hasMargin ? 2 : 0}
      mb={0}
      variant="warning"
      onClick={props.onClick}
      {...props}
    >
      <NoticeIcon className="notice-icon" width={30} height={30} />
      <Typography sx={{
        fontSize: "12px",
        fontWeight: "400",
      }}>
        <T>{alertKey}</T>
      </Typography>
    </Stack>    
  ))

  // hoverable tooltip for wider screens
  if (viewportIsSmall) {
    return hasTooltip ? (
      <Tooltip
        arrow
        placement="bottom"
        PopperProps={tooltipPopperProps}
        slotProps={tooltipSlotProps}
        title={<TooltipText translationKey={tooltipKey} />}
      >
        <Alert />
      </Tooltip>
    ) : (
      <Alert />
    )
  }

  // clickable tooltip for mobile
  return hasTooltip ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          placement="bottom"
          PopperProps={tooltipPopperProps}
          slotProps={tooltipSlotProps}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<TooltipText translationKey={tooltipKey} />}
        >
          <Alert onClick={handleTooltipOpen} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <Alert />
  )
};

export { GeneralAlert };

const TooltipText = ({ translationKey }) => {
  return <Typography
    sx={{
      color: "#fff",
      fontSize: "9px",
      fontWeight: "400",
      width: "100px"
    }}>
    <T>{translationKey}</T>
  </Typography>
}

const tooltipSlotProps = {
  arrow: {
    sx: {
      color: "#566E81"
    }
  },
  tooltip: {
    sx: {
      backgroundColor: "#566E81",
      textAlign: "center"
    }
  }
}

const tooltipPopperProps = {
  disablePortal: true,
}
import { OfficeBox } from "../OfficeBox"
import { Stack, Skeleton } from "@mui/material"


const SettingsItemSkeleton = () => {
  return (
    <OfficeBox
      innerRef={null}
      sx={{
        flex: 1,
        padding: '24px',
        minHeight: 400,
        borderRadius: '12px'
      }}
      data-test-id="settings-item-content"
    >
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Skeleton variant="rectangular" height={40} width="60%" />
        <Stack spacing={2} sx={{ mt: 3 }}>
          {[...Array(4)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={64}
              sx={{ borderRadius: 1 }}
              data-test-id={`settings-item-content`}
            />
          ))}
        </Stack>
      </Stack>
    </OfficeBox>
  )
}

export default SettingsItemSkeleton;
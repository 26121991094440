/**
 * Referral link config
 * The value for each country ranges from 1 to 3
 * 1: Pull data from Cosmic CMS
 * 2: Pull data from UShop
 * 3: Pull data from Drupal
 */
export const referralLinkConfig = {
  dz: 3,
  au: 3,
  at: 3,
  bs: 3,
  be: 3,
  ca: 1,
  co: 3,
  dk: 3,
  do: 1,
  eg: 3,
  fr: 3,
  de: 3,
  hu: 3,
  in: 3,
  ie: 3,
  it: 3,
  jo: 3,
  ke: 3,
  lb: 3,
  mx: 3,
  ma: 3,
  nl: 1,
  nz: 3,
  no: 3,
  om: 3,
  pl: 3,
  pr: 1,
  sa: 3,
  es: 3,
  se: 3,
  ch: 3,
  tr: 3,
  ua: 3,
  ae: 3,
  gb: 3,
  us: 1,
  sg: 2,
  my: 2,
  bn: 2,
  ph: 2,
  tw: 2,
  hk: 2,
  cz: 1
}
import { Stack, Skeleton, useTheme, useMediaQuery } from '@mui/material';
import { OfficeBox } from '../../components/OfficeBox';
import { forwardRef } from 'react';
import SettingsItemSkeleton from './SettingsItemSkeleton';

const SettingsScreenSkeleton = () => {
  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up('md'));

  const OptionsMenu = forwardRef((props, ref) => (
    <OfficeBox
      innerRef={ref}
      sx={styles.desktopContainer}
      data-test-id="settings-skeleton-options-container"
    >
      {[...Array(6)].map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          height={48}
          sx={{
            borderRadius: 1,
            mb: index < 5 ? 0.5 : 0
          }}
          data-test-id={`settings-skeleton-option-${index}`}
        />
      ))}
    </OfficeBox>
  ));

  return (
    <Stack
      data-test-id="settings-screen-skeleton"
      direction={viewportIsMedium ? 'row' : 'column'}
      gap={2}
      alignItems={viewportIsMedium ? 'flex-start' : 'stretch'}
    >
      {viewportIsMedium ? (
        <OptionsMenu />
      ) : (
        <Skeleton
          variant="rectangular"
          height={56}
          sx={{ borderRadius: 1 }}
          data-test-id="settings-skeleton-mobile-header"
        />
      )}
      <SettingsItemSkeleton />
    </Stack>
  );
};

const styles = {
  desktopContainer: {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    padding: '12px',
    width: '268px',
    borderRadius: '12px'
  }
};

export default SettingsScreenSkeleton; 
import { useState, useEffect } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import usePopup from 'hooks/usePopup';

const ratingLabels = {
  1: 'Unacceptable',
  2: 'Weak',
  3: 'Acceptable',
  4: 'Good',
  5: 'Excellent',
};

const PopupModal = ({
  open,
  onClose,
  data
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Reset index when modal opens/closes or data changes
    setCurrentIndex(0);
  }, [open, data]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };
  const {
    renderPopupContent
  } = usePopup(() => { }, ratingLabels)

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="popup-modal"
      aria-describedby="popup-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <Box
        data-test-id="popup-modal-content"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'calc(100% - 40px)',
          minWidth: '200px',
          maxWidth: '400px',
          maxHeight: '90vh',
          overflow: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: "12px",
          outline: 'none',
        }}
      >
        {data && data.length > 0 && renderPopupContent(data[currentIndex])}

        {data && data.length > 1 && (
          <Box data-test-id="carousel-buttons" sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            pointerEvents: 'none',
            px: 1
          }}>
            <IconButton
              onClick={handlePrev}
              sx={{
                bgcolor: 'rgba(255,255,255,0.7)',
                pointerEvents: 'auto'
              }}
              size="small"
              data-test-id="carousel-prev-button"
            >
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>

            <IconButton
              onClick={handleNext}
              sx={{
                bgcolor: 'rgba(255,255,255,0.7)',
                pointerEvents: 'auto'
              }}
              size="small"
              data-test-id="carousel-next-button"
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </Box>
        )}

        {data && data.length > 1 && (
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            gap: 1
          }}>
            {data.map((_, index) => (
              <Box
                key={index}
                onClick={() => setCurrentIndex(index)}
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  bgcolor: currentIndex === index ? 'primary.main' : 'grey.400',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease'
                }}
                data-test-id={`carousel-indicator-${index}`}
              />
            ))}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PopupModal;

import { Box, Chip, Typography } from "@mui/material";
import { T } from "../../hooks/useTranslate";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@mui/icons-material";

import { OfficeAvatar } from "./../OfficeAvatar";
import { OfficeBox } from "./../OfficeBox";

const SettingsButton = ({
  rankDetails,
  userInfo,
  menuIsOpen,
  viewportIsSmall,
  openMenu,
  menuAnchorRef
}) => {
  return (
    <OfficeBox
      aria-label="open settings menu"
      className="flex-row settings-menu"
      onClick={openMenu}
      innerRef={menuAnchorRef}
      sx={{
        padding: "10px"
      }}
      id="settingsContainer"
    >
      <OfficeAvatar
        id="settingsAvatar"
        imageUrl={userInfo?.profilePictureUrl}
        firstInitial={userInfo?.name ? userInfo?.name[0] : ""}
      />
      {viewportIsSmall && (
        <>
          <Box id="settingsUsername"
            ml={1}
            mr={1}
            sx={{
              fontWeight: 600,
              fontSize: "12px"
            }}
          >
            {userInfo.name}
          </Box>
          {
            rankDetails?.rankTranslateAbbrKey &&
            <Chip
              data-test-id="settings-button-rank-chip"
              className="u-chip"
              variant={`rank-${rankDetails?.rankId}`}
              label={<Typography variant="h9"><T>{rankDetails?.rankTranslateFullKey}</T></Typography>}
            />
          }
        </>
      )}
      <Box>
        {menuIsOpen ? (
          <ArrowDropUpOutlined />
        ) : (
          <ArrowDropDownOutlined />
        )}
      </Box>
    </OfficeBox>
  )
}

export { SettingsButton }
import { useNavigate, useLocation } from "react-router-dom";
import { useMixPanel } from "../../hooks/useMixPanel";

import { DataGridPro } from "@mui/x-data-grid-pro";

import { Box } from "@mui/material";

import "./ReferralTable.css";
import ReferralTableCellLeft from "./ReferralTableCellLeft";
import ReferralTableCellMid from "./ReferralTableCellMid";

const ReferralTable = ({ referrals }) => {
  const location = useLocation();
  const { logEvent } = useMixPanel();

  const columns = [
    {
      headerName: "MEMBER",
      headerClassName: "referralTableHeader",
      sortable: false,
      width: 175,
      field: "name",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => <ReferralTableCellLeft referral={params.row} />,
    },
    {
      headerName: "SUBSCRIPTION",
      headerClassName: "referralTableHeader",
      sortable: false,
      width: 110,
      field: "subscription",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <ReferralTableCellMid referral={params.row} />,
    },
    {
      headerName: "ORDERS",
      headerClassName: "referralTableHeader",
      sortable: false,
      width: 50,
      field: "orders",
      headerAlign: "right",
      align: "right",
    },
  ];

  const navigate = useNavigate();
  const handleRowClick = (params) => {
    localStorage.setItem("selectedMemberContext", JSON.stringify(params.row));
    logEvent("open_profile", {
      user_type: "member",
      location: "referrals_summary_drawer"
    });
    navigate(`${location.pathname}?drawer=member&id=${params.row.unicityId}&drawerContent=profile`, { state: location });
  }

  return (
    <Box id="ReferralDataGrid" sx={{ flexGrow: 1 }}>
      <DataGridPro
        columns={columns}
        rows={referrals}
        disableColumnMenu
        disableRowSelectionOnClick
        disableVirtualization
        autoHeight
        hideFooter
        getRowId={(row) => row.unicityId}
        onRowClick={handleRowClick}
        sx={{
          fontSize: "12px",
          border: "none",
        }}
      />
    </Box>
  );
};

export { ReferralTable };

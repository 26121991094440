import { Stack, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PartnersSkeleton = (): JSX.Element => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack 
      spacing={2} 
      sx={{ 
        width: '100%',
        px: { xs: 0, sm: 2 }
      }}
    >
      {/* Search and Filter Section */}
      <Skeleton 
        variant="rectangular" 
        height={120}
        sx={{ 
          width: '100%',
          borderRadius: "12px"
        }}
        data-test-id="partners-search-filter-skeleton"
      />

      {/* Main Content Box */}
      <Skeleton 
        variant="rectangular"
        height={viewportIsSmall ? 600 : 400}
        sx={{ 
          width: '100%',
          borderRadius: "12px"
        }}
        data-test-id="partners-table-skeleton"
      />
    </Stack>
  );
};

export default PartnersSkeleton; 
import React from "react";
import { T, useTranslateKey } from "../hooks/useTranslate";

import { Stack, Typography } from "@mui/material";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactComponent as NoticeIcon } from '../assets/icons/notice-icon.svg';

const GlobalNoticeComponent = () => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const { showBanner } = useFlags();
  const translatedKey = useTranslateKey(showBanner?.bannerKey);
  const bannerMessage = translatedKey !== `{{${showBanner?.bannerKey}}}` ? translatedKey : showBanner?.bannerMessage;

  return showBanner?.show &&
    (
      <ThemeProvider theme={theme}>
        <Stack
          className="notice-stack"
          flexDirection="row"
          alignItems="center"
          gap={1}
          p={1.5}
          mr={!viewportIsSmall && 2}
          ml={!viewportIsSmall && 2}
          variant={showBanner?.severity}
        >
          <Stack>
            <NoticeIcon className="notice-icon" width={20} height={20} />
          </Stack>
          <Typography sx={{
            fontSize: "12px",
            fontWeight: "400",
          }}>
            <strong><T>notice</T>:</strong> {bannerMessage}
          </Typography>
        </Stack>
      </ThemeProvider>
    )
}

export { GlobalNoticeComponent }
// taken from office.ng marketExceptions.js:Line 180
// used to hide countries that don't support Manage My Website Personal Settings in Old Office (Display Name in new office);
import { AMERICAS_COUNTRIES, ASIA_PACIFIC_COUNTRIES, EUROPE_COUNTRIES, MIDDLE_EAST_AFRICA_COUNTRIES } from "../screens/Library/constants/library.constants";

export const upgradeCountries = ["US", "CA", "MX", "PR", "DO", "CO", "JM"];

export const fgMarkets = ["SG", "MY", "BN", "PH", "TW", "HK"]
export const marketsWithLibrary = [...AMERICAS_COUNTRIES, ...ASIA_PACIFIC_COUNTRIES, ...EUROPE_COUNTRIES, ...MIDDLE_EAST_AFRICA_COUNTRIES].map(country => country.code)
export const fgMarketsWithNoLanguageSelect = ["BN", "PH", "MY", "SG", "HK", "TW"]
export const excludedCountry = ['DZ', 'BS', 'LB']
export const chineseCountries = ["TW", "HK"]
export const fgExcludedResourceLinks = ["new-library", "external-help", "shop-unicity"];
export const marketsWithNewLibrary = ["DO"]

// Usually, countries in this list just needs US as the fallback country.
export const marketsUsingUSLinks = ["CZ"]
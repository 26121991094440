import React from "react";

import { Stack, Typography } from "@mui/material";

const CopyrightSection = ({ hidden, padding }) => {
  const currentYear = new Date().getFullYear().toString();
  const textStyles = {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "9px",
    color: "#8A9BB0",
  };

  if (hidden) {
    return null;
  }
  return (
    <Stack gap={1} p={padding}>
      <Typography
        sx={textStyles}
      >
        {`© ${currentYear} Copyright Unicity International, Inc.`}
        <br />
        All rights reserved.
      </Typography>
      <Typography
        sx={textStyles}>
        Unicity Office Version {process.env.REACT_APP_VERSION}
      </Typography>
    </Stack>
  )
}

export { CopyrightSection }

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MemberContext } from "../../hooks/MemberContext";
import { Button } from "@mui/material";
import { ReactComponent as DownlineIcon } from '../../assets/icons/downline.svg';

const DrawerButton = ({ id, text, isDisabled, showFrontlineIcon, type }) => {
  const { memberId } = useContext(MemberContext);
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const drawer = searchParams.get("drawer");

  const handleButtonClick = () => {
    if (isDisabled) return;
    showFrontlineIcon ? navigate(`/partners/${memberId}`) :
      navigate(`${location.pathname}?drawer=${drawer}&id=${memberId}&drawerContent=${type}`)
  }

  return (
    <Button
      className="u-button"
      data-test-id={id}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onClick={handleButtonClick}
      sx={{
        opacity: isDisabled ? 0.3 : 1,
        cursor: isDisabled ? "not-allowed" : "pointer"
      }}
    >
      <div>{text}</div>&nbsp;
      {
        showFrontlineIcon ?
          <DownlineIcon className="downline-icon" /> :
          <KeyboardArrowRightIcon sx={{ transform: "scale(.8)" }} />
      }
    </Button>
  );
};

export { DrawerButton };

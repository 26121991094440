import * as React from "react";
import { useState, useEffect } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";
import { ActionableIndicator } from "../../components/ActionableIndicator";
import { useActionableItemsContext } from "../../hooks/ActionableItemsContext";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMixPanel } from "../../hooks/useMixPanel";

import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack
} from "@mui/material";
const BusinessNavItem = ({
  item,
  open,
  isMobile,
  drawerOpen,
  toggleDrawer
}) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { isActionable } = useActionableItemsContext();

  const [navState, setNavState] = useState(false);
  const translatedItemTitle = useTranslateKey(item.text);
  const { logEvent } = useMixPanel();

  const imgSrc = navState ? item.iconSrc.replace(".svg", "-selected.svg") : item.iconSrc;

  const handleNavClick = () => {
    logEvent(item.eventName, { location: "menu" });
    navigate(item.url);
  }

  const handleMobileNavClick = () => {
    logEvent(item.eventName, { location: "menu" });
    if (!navState) {
      toggleDrawer();
    }
    navigate(item.url);
  }

  useEffect(() => {
    // if the first part of the pathname matches the value of the nav item
    // set the nav item to selected
    if (location.pathname.split("/")[1] === item.value) {
      setNavState(true);
    } else {
      setNavState(false);
    }
    // eslint-disable-next-line
  }, [])

  if (isMobile) {
    return (
      <Box
        id={`${item.value}-nav-item`}
        data-test-id={`${item.value}-nav-item`}
        title={translatedItemTitle}
        sx={navState ? selectedBoxStyle : null}
      >
        <ListItem
          aria-label={item.text}
          value={item.value}
          onClick={handleMobileNavClick}
          disablePadding
          sx={{ display: "block" }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: drawerOpen ? "initial" : "center",
              px: 1.2,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <img src={imgSrc} alt={`${item.text} icon`} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Box sx={{ display: "flex", gap: "6.5px" }}>
                  <ActionableIndicator invisible={!isActionable(item.text)}>
                    <Stack direction="row" gap={.5} sx={{display: "inline-block"}}>
                      <T>{item.text}</T>
                      {(item.tags?.includes("beta")) && (
                        <Stack
                          justifyContent="center"
                          sx={{
                            fontSize: "8px",
                            padding: "2px 6px",
                            color: theme.subscription.subscribed.color,
                            backgroundColor: theme.subscription.subscribed.backgroundColor,
                            borderRadius: "3px",
                            display: "inline-block",
                          }}
                        >
                          <T>beta</T>
                        </Stack>
                      )}
                    </Stack>
                  </ActionableIndicator>
                </Box>
              }
              primaryTypographyProps={{
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "12px",
                color: navState ? "#009ECF" : null,
              }}
              sx={{ opacity: drawerOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </Box>
    );
  }
  return (
    <ListItem
      id={`${item.value}-nav-item`}
      data-test-id={`${item.value}-nav-item`}
      title={translatedItemTitle}
      aria-label={item.text}
      onClick={handleNavClick}
      value={item.value}
      disablePadding
      sx={{ display: "block" }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          mb: "10px"
        }}
        selected={navState}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          <ActionableIndicator padRight={-6} invisible={!(isActionable(item.text) && !open)}>
            <img style={{ display: 'inline-block' }} src={imgSrc} alt={`${item.text} icon`} />
          </ActionableIndicator>          
        </ListItemIcon>
        <ListItemText
          primary={
            <Box sx={{ display: "flex", gap: "6.5px" }}>
              <ActionableIndicator invisible={!isActionable(item.text)}>
                <Stack direction="row" gap={.5} sx={{display: "inline-block"}}>
                  <T>{item.text}</T>
                  {(item.tags?.includes("beta")) && (
                    <Stack
                      justifyContent="center"
                      sx={{
                        fontSize: "8px",
                        padding: "2px 6px",
                        color: theme.subscription.subscribed.color,
                        backgroundColor: theme.subscription.subscribed.backgroundColor,
                        borderRadius: "3px",
                        display: "inline-block",
                      }}
                    >
                      <T>beta</T>
                    </Stack>
                  )}
                </Stack>
              </ActionableIndicator>
            </Box>
          }
          primaryTypographyProps={{
            fontFamily: "Inter",
            fontWeight: "600",
            fontSize: "12px",
            color: navState ? "#009ECF" : null,
          }}
          sx={{ opacity: open ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const selectedBoxStyle = {
  backgroundColor: "rgba(0, 158, 207, 0.1)",
  borderRadius: "8px",
};

export { BusinessNavItem };

import { Box, Typography } from "@mui/material";
import LazyFlag from "../common/LazyFlag";
import FlagIcon from "@mui/icons-material/Flag";
import { T } from "../../hooks/useTranslate";
import { formatLocation } from "../../common/format-functions";
import { gridTextStyle } from "./drawer-styles";

const ProfileLocationLine = ({ customerHrefData, drawerType }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {customerHrefData.mainAddress?.country ? (
        <LazyFlag
          code={customerHrefData.mainAddress?.country}
          height="16"
          width="16"
          style={{ borderRadius: "2px" }}
        />
      ) : (
        <FlagIcon sx={{ width: 16, height: 16 }} />
      )}
      <Typography sx={{
        ...gridTextStyle,
        marginLeft: 1
      }} color="text.secondary" data-testid={`${drawerType}-profile-location`}>
        {customerHrefData.mainAddress ? 
          formatLocation({ 
            city: customerHrefData.mainAddress.city, 
            state: customerHrefData.mainAddress.state, 
            country: customerHrefData.mainAddress.country 
          }) : 
          <T>not_available</T>
        }
      </Typography>
    </Box>
  );
};

export { ProfileLocationLine };
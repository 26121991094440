import { useState } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";
import { Alert, Box, LinearProgress } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";

import { useApi } from "../../hooks/useApi";
import { AvatarChip } from "./AvatarChip";
import { MemberContext } from "../../hooks/MemberContext";
import { LanguageContext } from "../../hooks/LanguageContext";

import { Chip, Typography } from "@mui/material";
import { OfficeHorizontalRule } from "../OfficeHorizontalRule";
import { OrderNumberChip } from "./OrderNumberChip";
import { OrderDetailItem } from "./OrderDetailItem";
import { formatDateByLocale } from "../../common/date-functions";
import { useMixPanel } from "../../hooks/useMixPanel";

const OrderDetailDrawerContent = ({ isWebView, orderHref }) => {
  const [currency, setCurrency] = useState("USD");

  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();

  const { response, status, sendRequest } = useApi();

  const { memberData, customerHrefData, setIsDrawerOpen } = useContext(MemberContext);
  const { currentLanguage, languageDir } = useContext(LanguageContext);

  const statusTable = {
    Fulfilled: {
      translateKey: "Shipped",
    },
    Cancelled: {
      translateKey: "Cancelled",
    },
    ReadyToProcess: {
      translateKey: "Prepared to Ship",
    },
    Processing: {
      translateKey: "Processing",
    },
    Unfulfilled: {
      translateKey: "Unfulfilled",
    },
    Hold: {
      translateKey: "Hold",
    },
    Delivered: {
      translateKey: "Delivered",
    },
    Backordered: {
      translateKey: "Back Ordered",
    },
    Returned: {
      translateKey: "Returned",
    },
    Canceled: {
      translateKey: "Canceled",
    },
  }

  const ORDER_IS_FULFILLED = response?.data?.fulfillmentStatus === "Fulfilled";
  const ORDER_IS_AUTOSHIP = response?.data?.type === "Autoship";

  const translateKey = useTranslateKey;
  const fulfillmentText = statusTable[response?.data?.fulfillmentStatus]?.translateKey;
  const shippingStatusText = ORDER_IS_AUTOSHIP ? translateKey("subscription") : translateKey("one_time_purchase");

  const summaryRowStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "12px",
  };

  const totalRowStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "12px",
    fontWeight: "600",
  };

  useEffect(() => {
    if (status === "uninitialized")
      sendRequest({
        method: "get",
        endpoint: `orders/${orderHref}`,
      });

    // if currency is available, localize
    if (response?.data?.currency) {
      setCurrency(response?.data?.currency);
    }
  }, [orderHref, sendRequest, status, response?.data?.currency]);

  let CurrencyLocalizer = new Intl.NumberFormat(currentLanguage, {
    style: "currency",
    currency: currency,
  });

  const getProductCount = () => {
    const products = response.data?.lines?.items?.filter(item => item.terms.priceEach > 0)

    return products?.length || 0
  }

  const getProductDetails = () => {
    const filteredItemsWithPv = response.data?.lines?.items?.filter((item) => {
      return item.terms.priceEach > 0;
    });

    return filteredItemsWithPv?.map((item, idx) => {
      return <OrderDetailItem
        key={idx}
        item={item}
        CurrencyLocalizer={CurrencyLocalizer} />;
    })
  }

  const searchParams = new URLSearchParams(location.search);
  const drawer = searchParams.get("drawer");

  const handleArrowClick = () => {
    navigate(-1, { replace: true });
  }

  const handleCloseClick = () => {
    setIsDrawerOpen(false);
    navigate(`${location.pathname}`, { replace: true });
  }

  const handleChipClick = () => {
    logEvent("open_profile", {
      location: "order_detail_drawer",
      user_type: customerHrefData.type
    })
    navigate(`${location.pathname}?drawer=${drawer}&id=${memberData.id.unicity}&drawerContent=profile`, { state: location });
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "24px 0px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: isWebView ? "flex-end" : "space-between",
          padding: "0px 24px",
        }}
      >
        {!isWebView && (languageDir === "ltr" ?
          <KeyboardBackspaceIcon
            data-test-id="c_order_detail_back_button"
            onClick={handleArrowClick}
            sx={{ cursor: "pointer" }}
          /> :
          <ArrowForwardIcon
            data-test-id="c_order_detail_back_button"
            onClick={handleArrowClick}
            sx={{ cursor: "pointer" }}
          />)}
        <CloseIcon 
          data-test-id="c_order_detail_close_button"
          onClick={handleCloseClick} 
          sx={{ cursor: "pointer" }} 
        />
      </Box>
      <Box
        data-test-id="c_order_detail_avatar_section"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: isWebView ? "flex-end" : "space-between",
          padding: "28px 24px",
        }}
      >
        {customerHrefData?.humanName?.firstName !== undefined && (
          <AvatarChip
            data-test-id="c_order_detail_avatar_chip"
            id="orderAvatarChip"
            imageUrl={memberData?.profilePictureUrl}
            name={`${customerHrefData?.humanName?.firstName} ${customerHrefData?.humanName?.lastName}`}
            clickHandler={handleChipClick}
          />
        )}
      </Box>
      <Box
        data-test-id="c_order_detail_info_section"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0px 24px",
        }}
      >
        <div>
          <div
            style={{ fontSize: "16px", fontWeight: "600", marginRight: "10px" }}
            data-test-id="c_order_detail_title"
          >
            <T>order_details</T>
          </div>
          {status === "complete" && (
            <>
              <div
                style={{ fontWeight: "400", fontSize: "12px", opacity: ".6" }}
                data-test-id="c_order_detail_metadata"
              >
                {response.data.id.unicity} •{" "}
                {formatDateByLocale(currentLanguage, response.data.dateCreated)}
              </div>
              {response?.data?.shipments?.items && (
                <div
                  data-test-id="c_order_detail_tracking"
                  style={{
                    fontWeight: 400,
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  <T>tracking_number</T>:{" "}
                  <a
                    data-test-id="c_order_detail_tracking_link"
                    target="blank"
                    id="trackingNumberLink"
                    href={response.data.shipments.items[0]?.trackingNumberHref}
                  >
                    {response.data.shipments.items[0]?.trackingNumber}
                  </a>
                </div>
              )}
            </>
          )}
        </div>
        {status === "complete" && (
          <div 
            data-test-id="c_order_detail_status_chips"
            style={{ display: "flex", alignItems: "end", flexDirection: "column", gap: "5px" }}
          >
            {fulfillmentText && (
              <>
                <Chip
                  id="fulfillmentStatusChip"
                  data-test-id="c_order_detail_fulfillment_chip"
                  className="u-chip"
                  variant={response?.data?.fulfillmentStatus}
                  label={<Typography variant="h9">{fulfillmentText}</Typography>}
                  labelStyle={{ opacity: ORDER_IS_FULFILLED ? 0.5 : 1 }}
                />
              </>
            )}
            <Chip
              id="autoshipStatusChip"
              data-test-id="c_order_detail_type_chip"
              className="u-chip"
              label={<Typography variant="h9">{shippingStatusText}</Typography>}
              variant={ORDER_IS_AUTOSHIP ? "subscription-chip-subscribed" : "subscription-chip-one-time-purchase"}
            />
          </div>
        )}
      </Box>
      {status === "complete" && <OfficeHorizontalRule />}
      {status === "complete" && (
        <Box data-test-id="c_order_detail_content">
          <Box sx={{ padding: "0px 24px" }}>
            <Box>
              <span
                style={{ fontSize: "14px", fontWeight: "600" }}
                data-test-id="order-details-products-header"
              >
                <T>products</T>
              </span>{" "}
              <span data-test-id="order-details-products-chip">
                <OrderNumberChip>{getProductCount()}</OrderNumberChip>
              </span>
            </Box>
            <Box sx={{ marginTop: "27px" }}>
              {getProductDetails()}
            </Box>
          </Box>
          <OfficeHorizontalRule />
          <Box sx={{ padding: "0px 24px" }}>
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                marginTop: "16px",
                marginBottom: "24px",
              }}
              data-test-id="order-details-summary-header"
            >
              <T>summary</T>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <Box sx={summaryRowStyle}>
                <Box data-test-id="order-details-summary-subtotal-label">
                  <T>subtotal</T>
                </Box>
                <Box data-test-id="order-details-summary-subtotal-value">{`${CurrencyLocalizer.format(
                  response.data.terms.subtotal
                )}`}</Box>
              </Box>
              {
                // if a discount amount, and it is greater than 0, display the discount row
                response.data?.terms?.discount?.amount > 0 && (
                  <Box sx={summaryRowStyle}>
                    <Box data-test-id="order-details-summary-discount-label">
                      <T>discount</T>
                    </Box>
                    <Box data-test-id="order-details-summary-discount-value">{`-${CurrencyLocalizer.format(
                      response.data.terms.discount.amount
                    )}`}
                    </Box>
                  </Box>
                )
              }
              <Box sx={summaryRowStyle}>
                <Box data-test-id="order-details-summary-shipping-label">
                  <T>shipping</T>
                </Box>
                <Box data-test-id="order-details-summary-shipping-value">{`${CurrencyLocalizer.format(
                  response.data.terms.freight.amount
                )}`}</Box>
              </Box>
              <Box sx={summaryRowStyle}>
                <Box data-test-id="order-details-summary-pv-label"><T>pv</T></Box>
                <Box data-test-id="order-details-summary-pv-value">
                  {response.data.terms.pv}
                </Box>
              </Box>
              <Box sx={summaryRowStyle}>
                <Box data-test-id="order-details-summary-taxes-label"><T>taxes</T></Box>
                <Box data-test-id="order-details-summary-taxes-value">{`${CurrencyLocalizer.format(
                  response.data.terms.tax.amount
                )}`}</Box>
              </Box>
              <Box sx={totalRowStyle}>
                <Box data-test-id="order-details-summary-total-label"><T>total</T></Box>
                <Box data-test-id="order-details-summary-total-value">{`${CurrencyLocalizer.format(
                  response.data.terms.total
                )}`}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {status === "wait" && (
        <LinearProgress 
          data-test-id="c_order_detail_loading"
          sx={{ width: "90%", margin: "24px auto" }} 
        />
      )}
      {status === "error" && (
        <Alert 
          data-test-id="c_order_detail_error"
          id="ordersError" 
          severity="error"
        >
          <T>generic_error</T>
        </Alert>
      )}
    </Box>
  );
};

export { OrderDetailDrawerContent };

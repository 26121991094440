import { Box, Skeleton, useTheme, useMediaQuery } from "@mui/material";

const ActionHubSkeleton = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const ActionHubItemSkeleton = () => (
    <Box
      sx={{
        width: {
          xs: "100%",
          sm: "calc(50% - 16px)",
          md: "calc(33.33% - 16px)",
        },
        maxWidth: {
          xs: "100%",
          sm: "300px",
          md: "350px",
        },
        height: {
          xs: "100px",
          sm: "110px",
          md: "150px",
        },
        borderRadius: "8px",
        background: theme.palette.background.paper,
      }}
    >
      <Skeleton 
        variant="rectangular"
        width="100%"
        height="100%"
        sx={{ borderRadius: "8px" }}
      />
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: {
            xs: "16px",
            sm: "20px",
            md: "24px",
          },
          padding: "24px",
          background: theme.palette.background.paper,
          borderRadius: "12px",
          width: "100%",
          maxWidth: "100%",
          boxSizing: "border-box",
          margin: 0,
        }}
      >
        <Box 
          sx={{
            width: {
              xs: "200px",
              sm: "250px",
              md: "300px",
            },
            height: {
              xs: "32px",
              sm: "36px",
              md: "40px",
            }
          }}
        >
          <Skeleton 
            variant="text" 
            width="100%"
            height="100%"
            data-test-id="action-hub-skeleton-header" 
          />
        </Box>
        
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: isSm ? "column" : "row",
            },
            gap: 2,
            justifyContent: {
              xs: "flex-start",
              sm: "center"
            },
            alignItems: {
              xs: "stretch",
              sm: "center"
            },
            flexWrap: "wrap",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <ActionHubItemSkeleton />
          <ActionHubItemSkeleton />
          <ActionHubItemSkeleton />
        </Box>
      </Box>
      
      <Box 
        sx={{ 
          display: "flex", 
          justifyContent: "center", 
          width: "100%",
          boxSizing: "border-box",
        }}
        mt={5}
      >
        <Box 
          sx={{
            width: {
              xs: "160px",
              sm: "180px",
              md: "200px",
            },
            height: {
              xs: "32px",
              sm: "36px",
              md: "40px",
            }
          }}
        >
          <Skeleton 
            variant="rectangular" 
            width="100%"
            height="100%"
            sx={{ borderRadius: "4px" }} 
          />
        </Box>
      </Box>
    </>
  );
};

export default ActionHubSkeleton; 
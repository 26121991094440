import React from 'react';
import { Stack, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNews } from "../../../hooks/useNews";
import { ArticleDetailView } from './ArticleDetailView';
import { LoadingSkeleton } from '../../../screens/DashboardScreen/News/LoadingSkeleton';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';

const ArticleDrawerContent = ({ articleId, setIsDrawerOpen }) => {
  const { news, loading } = useNews();
  const newsArticle = news.find((article) => article.id === parseInt(articleId));
  const navigate = useNavigate();
  const location = useLocation();

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    navigate(`${location.pathname}`, { replace: true });
  };

  if (loading) {
    return (
      <Box
        p={3}
        data-test-id="news-article-drawer-content-loading"
      >
        <CloseIcon
          className="close-icon"
          height="24px"
          width="24px"
          style={{ cursor: "pointer", marginBottom: "24px" }}
          onClick={handleCloseDrawer}
        />
        <LoadingSkeleton heights={[288, 70, 150]} />
      </Box>
    );
  }

  return (
    <Stack
      p={3}
      data-test-id="news-article-drawer-content"
    >
      <CloseIcon
        className="close-icon"
        height="24px"
        width="24px"
        style={{ cursor: "pointer" }}
        onClick={handleCloseDrawer}
      />
      {newsArticle && (
        <ArticleDetailView
          article={newsArticle}
        />
      )}
    </Stack>
  );
};

export { ArticleDrawerContent };

import React from "react";
import { T } from "../hooks/useTranslate";
import { Chip, Typography } from "@mui/material";
import { RANK_DETAILS } from "../common/rankDetails";

/**
 * @description Rank chip component
 * @param {Object} params
 * @param {string} params.rankCode
 * @returns {React.ReactNode}
 */
const RankChip = ({ rankCode }) => {
  const rankIndex = RANK_DETAILS.findIndex(rank => rank.rankCode === rankCode);
  return rankIndex !== -1 ? (
    <Chip
      className="u-chip"
      variant={`rank-${RANK_DETAILS[rankIndex]?.rankId}`}
      label={<Typography variant="h9"><T>{RANK_DETAILS[rankIndex]?.rankTranslateFullKey}</T></Typography>}
    />
  ) : null
}

export { RankChip } 
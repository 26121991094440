import React from "react"
import { useState } from "react"

import { Box } from "@mui/material"
import { IconButton } from "@mui/material"

import { MobileNavDrawer } from "./MobileNavDrawer"
import { businessDrawerItems, resourceDrawerItems } from "./nav-drawer-items";
import { ActionableIndicator } from "../../components/ActionableIndicator";
import { useActionableItemsContext } from "../../hooks/ActionableItemsContext";
import { ReactComponent as MenuIcon } from '../../assets/icons/menu-icon.svg';

const MobileNavIcon = ({ shopLink }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isActionableArrayOfStrings } = useActionableItemsContext();
  const keys = [...resourceDrawerItems, ...businessDrawerItems].map(businessDrawerItem => businessDrawerItem.text);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  
  return (
    <>
      <Box className="flex-row">
        <ActionableIndicator
          invisible={!(isActionableArrayOfStrings(keys))}
          padRight={-6}
        >
          <IconButton
            id="nav-icon"
            data-test-id="nav-icon"
            aria-label="toggle mobile navigation"
            onClick={toggleDrawer}
            sx={{
              height: "40px",
              width: "40px",
              border: "1px solid rgba(145, 158, 171, 0.1)",
              boxShadow: "0px 1px 3px rgba(145, 158, 171, 0.1)",
              borderRadius: "8px"
            }}
          >
            <MenuIcon className="menu-icon" />
          </IconButton>
        </ActionableIndicator>
      </Box>
      <MobileNavDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} shopLink={shopLink} />
    </>
  )
}

export { MobileNavIcon }
import { Chip } from "@mui/material";
import { OfficeAvatar } from "../OfficeAvatar";

const AvatarChip = ({ id, imageUrl, name, clickHandler }) => {
  return (
    <Chip
      className="u-chip"
      variant="avatar"
      avatar={
        <OfficeAvatar
          id={`${id}-avatar`}
          imageUrl={imageUrl}
          firstInitial={name[0]}
        />
      }
      label={name}
      data-test-id="avatar-chip"
      id={id}
      onClick={clickHandler}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
    />
  );
};

export { AvatarChip };

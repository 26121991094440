import React, { useContext } from "react"
import { Box } from "@mui/material"
import { LanguageContext } from "../../hooks/LanguageContext";
import { formatDateShortByLocale } from "../../common/date-functions";
import { useTheme } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { T } from "../../hooks/useTranslate";

const ReferralTableCellMid = ({ referral }) => {
  const { currentLanguage } = useContext(LanguageContext);
  const theme = useTheme();
  const subscriptionRenewalDate = referral.subscriptionRenewalDate;

  return (
    subscriptionRenewalDate 
      ? <Box sx={{
        backgroundColor: theme.subscription.subscribed.backgroundColor,
        color: theme.subscription.subscribed.color,
        fontWeight: "500",
        fontSize: "9px",
        padding: "2px 8px",
        borderRadius: "38px"
      }}>
        <T>next</T>: {formatDateShortByLocale(currentLanguage, subscriptionRenewalDate)}
    </Box>
    : <Chip
        label="no"
        sx={{
          borderRadius: "38px",
          fontSize: "10px",
          fontWeight: 600,
          textAlign: "center",
          marginLeft: '8px',
          height: "20px",
          width: "fit-content",
        }}
       />
  )
}

export default ReferralTableCellMid
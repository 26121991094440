import { Chip } from "@mui/material";

const OrderNumberChip = ({ id, children }) => {
  return (
    <Chip
      style={{
        fontSize: "10px",
        width: "fit-content",
        height: "20px",
        fontWeight: "600",
      }}
      data-test-id={id}
      label={children}
    />
  );
};

export { OrderNumberChip };

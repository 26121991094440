import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";
import { useFormik } from "formik";

import {
  Modal,
  Typography,
  Stack,
  FormControl,
  RadioGroup,
  Grid,
  Switch
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { LanguageModalOption } from "./LanguageModalOption";
import { LanguageContext } from "../../hooks/LanguageContext";
import { LANGUAGE_DETAILS } from "../../common/languageDetails";
import { LanguageContextType, LanguageModalProps, LanguageFormValues } from "../../types/components/language";

import { LanguageModalButtons } from "./LanguageModalButtons";

const LanguageModal: React.FC<LanguageModalProps> = ({ open, handleModalClose, handleClose }) => {
  const { currentLanguage, setCurrentLanguage, languageDir, setLanguageDir } = useContext<LanguageContextType>(LanguageContext);

  // Get the countryCode that matches the current i18nCode
  const initialCountryCode = LANGUAGE_DETAILS.find(item => item.i18nCode === currentLanguage)?.countryCode || "en";

  const handleRadioChange = () => {
    formik.setErrors({});
    return formik.handleChange;
  };

  const handleApplyClick = (values: LanguageFormValues) => {
    const i18nCode = LANGUAGE_DETAILS.find(item => item.countryCode === values.selection)?.i18nCode;
    if (i18nCode) {
      setCurrentLanguage(i18nCode);
      handleModalClose();
      handleClose();
    }
  }

  // configure Formik to handle form state
  const formik = useFormik<LanguageFormValues>({
    initialValues: {
      selection: initialCountryCode,
    },
    onSubmit: handleApplyClick,
  });

  // reset selection when modal closes
  const handleModalCancel = () => {
    handleModalClose();
    formik.setFieldValue("selection", currentLanguage)
  }

  const handleRtlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLanguageDir(languageDir === "rtl" ? "ltr" : "rtl")
  }

  return (
    <Modal
      open={open}
      onClose={handleModalCancel}
      aria-labelledby="modal-language-picker"
      aria-describedby="modal-language-picker-menu"
    >
      <form data-test-id={`language-form`} onSubmit={formik.handleSubmit}>
        <Stack className="language-modal-desktop">
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={1}>
            <Typography variant="subtitle1"><T isUpperCase={true}>change_language</T></Typography>
            <CloseIcon onClick={handleModalClose} sx={{ cursor: "pointer", height: "16px", width: "16px" }} />
          </Stack>
          <Stack sx={{ fontWeight: "500", fontSize: "14px", padding: "0px 12px" }} alignItems={"flex-start"} mb={3}>
            <FormControl>
              <RadioGroup
                aria-labelledby="language-radio-buttons-group-label"
                defaultValue="spanish"
                name="language-radio-group"
                onChange={handleRadioChange}
                value={formik.values.selection}
              >
                <Grid container spacing={0} rowSpacing={0} maxWidth={"367px"} p="0px 12px">
                  {LANGUAGE_DETAILS.map((language, idx) => {
                    return <Grid item xs={6} key={idx}>
                      <LanguageModalOption
                        formik={formik}
                        value={language.countryCode}
                        label={language.label}
                        data-test-id={`language-option-${language.countryCode}`}
                      />
                    </Grid>
                  })}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {
              process.env.REACT_APP_IS_PRODUCTION === "false" && (
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography variant="h6">SET RIGHT-TO-LEFT</Typography>
                  <Switch checked={languageDir === "rtl"} onChange={handleRtlChange} />
                </Stack>
              )
            }
            <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
              <LanguageModalButtons handleModalCancel={handleModalCancel} />
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Modal>
  )
}

export { LanguageModal }

import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { useLocation, useNavigate } from "react-router-dom";

import { Stack, Typography, Divider, Box } from "@mui/material";

import { OfficeAvatar } from "../OfficeAvatar";
import { LanguageContext } from "../../hooks/LanguageContext";

import { formatDateByLocale } from "../../common/date-functions"
import { DateTime } from "luxon";
import { useMixPanel } from "hooks/useMixPanel";

const toDisplayEvents = [
  "new-auto-order",
  "created-order-for-existing-customer",
  "created-order-with-transactional-customer",
  "upgraded-customer-type-from-member-to-associate"
]

const nonOrderEvents = [
  "upgraded-customer-type-from-member-to-associate"
]

const RenderName = ({ firstName, lastName, isClickable }) => {
  return (
    <Typography
      data-test-id="activity-feed-item-name"
      variant="span"
      sx={{ fontWeight: "bold", cursor: isClickable ? "pointer" : "default" }}
    >
      {firstName}{lastName ? ` ${lastName}` : ""}
    </Typography>
  )
}

const RenderEventDetails = ({
  hydraEventType,
  orderDetails,
  localizeNumber,
  isClickable
}) => {
  switch (hydraEventType) {
    case "new-auto-order":
      return (
        <>
          &nbsp;<T>started_a</T>&nbsp;
          {orderDetails
            ? (
              <>
                <Typography
                  data-test-id={`activity-feed-item-pv-value`}
                  variant="span"
                  sx={{ fontWeight: "bold", cursor: isClickable ? "pointer" : "default" }}>
                  {localizeNumber(orderDetails.pv)}&nbsp;<T>pv</T>
                </Typography>&nbsp;
                <T>subscription_low</T>.
              </>
            )
            : (<><T>subscription_low</T>.</>)
          }
        </>
      )
    case "created-order-for-existing-customer":
      return (
        <>
          &nbsp;{orderDetails ? <T>made_a</T> : <T>made_an</T>}&nbsp;
          {
            orderDetails &&
            <>
              <Typography
                data-test-id={`activity-feed-item-pv-value`}
                variant="span"
                sx={{ fontWeight: "bold", cursor: isClickable ? "pointer" : "default" }}>
                {localizeNumber(orderDetails.pv)}&nbsp;<T>pv</T>
              </Typography>&nbsp;
            </>
          }
          <T>order</T>.
        </>
      )
    case "created-order-with-transactional-customer":
      return (
        <>
          &nbsp;<T>made_their_first</T>&nbsp;
          {
            orderDetails &&
            <>
              <Typography
                data-test-id={`activity-feed-item-pv-value`}
                variant="span"
                sx={{ fontWeight: "bold", cursor: isClickable ? "pointer" : "default" }}>
                {localizeNumber(orderDetails.pv)}&nbsp;<T>pv</T>
              </Typography>&nbsp;
            </>
          }
          <T>order</T>.
        </>
      )
    case "upgraded-customer-type-from-member-to-associate":
      return (
        <>
          &nbsp;
          <T>is_now_a</T>
          &nbsp;
          <Typography variant="span" sx={{ fontWeight: "bold", cursor: isClickable ? "pointer" : "default" }}>
            <T>distributor</T>
          </Typography>.
        </>
      )
    default:
      return null
  }
}

const ActivityFeedItem = ({ props, isLastItem, handleClose, isDashboard }) => {
  const { currentLanguage, localizeNumber } = useContext(LanguageContext);
  const isSeen = props.isSeen === "true";
  const isClickable = (props?.orderDetails ||
    nonOrderEvents.includes(props.hydraEventType)) &&
    props.hydraEventType !== "new-auto-order"

  const location = useLocation();
  const navigate = useNavigate();
  const { logEvent } = useMixPanel();

  if (!toDisplayEvents.includes(props.hydraEventType)) {
    return null
  }

  const handleItemClick = () => {
    logEvent('recent_activity_feed_item_click')
    if (!isClickable) {
      logEvent('recent_activity_feed_item_not_clickable', {
        activityId: props.activityId,
        distributorId: props.distributorId,
        distributorName: `${props.firstName} ${props.lastName}`,
        hydraEventType: props.hydraEventType,
        memberId: props.memberId,
      })
      return;
    }
    const orderHref = props?.orderDetails?.href?.split("/").slice(-1)[0]
    const navigateTo = orderHref
      ? `${location.pathname}?drawer=member&id=${props.memberId}&drawerContent=orderDetails&orderHref=${orderHref}`
      : `${location.pathname}?drawer=partner&id=${props.memberId}&drawerContent=profile`
    navigate(navigateTo, { state: location });
  }

  return (
    <Box
      className="activity-feed-item"
      sx={{
        cursor: isClickable ? "pointer" : "default"
      }}
      onClick={handleItemClick}
      data-test-id="activity-feed-item"
    >
      <Stack direction="row" pl={1} pr={2.5}>
        {
          isDashboard || isSeen ?
            <Box width={"13px"}></Box> :
            <Stack justifyContent="center" mr={1}>
              <img src="icons/new-activity.svg" alt="new activity indicator" style={{ height: "5px", width: "5px" }} />
            </Stack>

        }
        <Stack justifyContent="center" mr={1.5}>
          <OfficeAvatar
            id="activity-feed-item-avatar"
            height={24}
            width={24}
            firstInitial={props.firstName[0]}
          />
        </Stack>
        <Stack>
          <Stack>
            <Typography sx={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "12px"
            }}>
              <RenderName
                firstName={props.firstName}
                lastName={props.lastName}
                isClickable={isClickable}
              />
              <RenderEventDetails 
                localizeNumber={localizeNumber}
                isClickable={isClickable}
                {...props} 
              />
            </Typography>
          </Stack>
          <Stack>
            <Typography
              data-test-id={`activity-feed-item-date`}
              sx={{
                fontWeight: "500",
                fontSize: "10px",
                opacity: ".6",
                lineHeight: "12px"
              }}>
              {formatDateByLocale(currentLanguage, props.dateCreated, DateTime.DATETIME_MED)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {!isLastItem && <Divider sx={{ marginTop: "16px" }} />}
    </Box>

  )
}

export { ActivityFeedItem }
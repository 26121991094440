import { Box, Button, Stack, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { T } from "../../hooks/useTranslate";
import { useLanguageContext } from "../../hooks/LanguageContext";
import usePopup from "../../hooks/usePopup";
import { useMixPanel } from "../../hooks/useMixPanel";
import { useAuth } from "../../hooks/useAuth";

interface AnnouncementProps {
  data: {
    titleTextKey: string;
    subtextKey: string;
    subtextKey2: string;
    buttonTextKey: string;
    link: string;
  };
  onClose?: () => void;
}

const Announcement = ({ data }: AnnouncementProps) => {
  const { currentLanguage } = useLanguageContext();
  const { handleClosePopup } = usePopup()
  const { logEvent } = useMixPanel()
  // @ts-ignore
  const { userInfo } = useAuth()
  const getImage = () => {
    switch (currentLanguage) {
      case "fr":
        return "assets/jpg/announcement-fr.jpg";
      case "es":
        return "assets/jpg/announcement-es.jpg";
      default:
        return "assets/jpg/announcement-en.jpg";
    }
  }

  const handleRedirect = () => {
    logEvent("announcement_clicked", {
      link: data?.link,
      unicity_id: userInfo?.unicityId,
      country_code: userInfo?.countryCode,
    })
    window.open(data.link, "_blank");
    handleClosePopup()
  }

  return (
    <Box sx={{ maxWidth: "100%", overflow: "hidden", position: "relative" }}>
      <IconButton
        onClick={() => handleClosePopup()}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 10,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }
        }}
        data-test-id="announcement-close-button"
        aria-label="close announcement"
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ width: "100%" }}>
        <img
          src={getImage()}
          alt="announcement"
          style={{
            width: "100%",
            maxHeight: "430px",
            objectFit: "cover"
          }}
        />
      </Box>
      <Stack px={3} pb={5}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "120%",
            mt: 2
          }}
        >
          <T>{data.titleTextKey}</T>
        </Typography>
        <Stack>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "150%",
              mt: 2,
              wordBreak: "break-word",
              overflowWrap: "break-word",
              maxWidth: "100%"
            }}
          >
            <T>{data.subtextKey}</T>
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "150%",
              mt: 2,
              wordBreak: "break-word",
              overflowWrap: "break-word",
              maxWidth: "100%"
            }}
          >
            <T>{data.subtextKey2}</T>
          </Typography>
        </Stack>
        {/* <Link
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
            textDecoration: 'none'
          }}
          href={data.link}
          target="_blank"
        > */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          textDecoration: 'none'
        }}>
          <Button
            data-test-id="announcement-button"
            onClick={handleRedirect}
            sx={{
              borderRadius: "33px",
              backgroundColor: "#003764",
              '&:hover': {
                backgroundColor: "#003764",
              },
              color: "#FFFFFF",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "100%",
              width: "55%",
              p: 1.5
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "120%",
                textAlign: "center"
              }}
            >
              <T>{data.buttonTextKey}</T>
            </Typography>
          </Button>
        </Box>

        {/* </Link> */}
      </Stack>
    </Box>
  )
}

export default Announcement
import { CircularProgress, Stack } from '@mui/material';
import DashboardSkeleton from './DashboardSkeleton';
import MembersSkeleton from './MembersSkeleton';
import PartnersSkeleton from './PartnersSkeleton';
import ActionHubSkeleton from './ActionHubSkeleton';
import ActionHubItemLoader from './ActionHubItemLoader';
import { CenturionSkeleton } from './CenturionSkeleton';
import ReferralLinksSkeleton from './ReferralLinksSkeleton';
import EarningsLoader from './EarningsLoader';
import SettingsScreenSkeleton from './SettingsScreenSkeleton';
import { useLocation } from 'react-router-dom';
import SettingsItemSkeleton from './SettingsItemSkeleton';
import { LoginScreenSkeleton } from './LoginScreenSkeleton';
import LibraryScreenSkeleton from './LibraryScreenSkeleton';

const loaders = {
  dashboard: <DashboardSkeleton />,
  members: <MembersSkeleton />,
  partners: <PartnersSkeleton />,
  action_hub: <ActionHubSkeleton />,
  new_feel_great_members: <ActionHubItemLoader />,
  order_reminders: <ActionHubItemLoader />,
  partials: <ActionHubItemLoader />,
  centurion: <CenturionSkeleton />,
  referral_links: <ReferralLinksSkeleton />,
  earnings: <EarningsLoader />,
  statements: <EarningsLoader />,
  settings: <SettingsScreenSkeleton />,
  'my-profile': <SettingsItemSkeleton />,
  'personal-information': <SettingsItemSkeleton />,
  'sponsor-and-enroller': <SettingsItemSkeleton />,
  'placement-program': <SettingsItemSkeleton />,
  'electronic-id': <SettingsItemSkeleton />,
  login: <LoginScreenSkeleton />,
  library: <LibraryScreenSkeleton />,
}

const LoadingFallback = (props) => {
  const { pageTitle } = props;
  const { pathname } = useLocation();
  const key = (pageTitle === undefined || pageTitle === 'sponsor-and-enroller') ? pathname.split('/').pop() : pageTitle;

  const loader = loaders[key];
  return (
    loader
      ? loader
      : (
        <Stack justifyContent="center" alignItems="center" height="200px">
          <CircularProgress data-test-id="lazy-loading-spinner" />
        </Stack>
      )
  )
}

export default LoadingFallback;
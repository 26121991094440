import React, { useContext, useState, useEffect } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";
import { useMixPanel } from "../../hooks/useMixPanel";
import { List, Stack, Typography, Divider, TextField, Menu, MenuItem, CircularProgress } from "@mui/material";
import { LanguageContext } from "../../hooks/LanguageContext";
import { DateTime } from "luxon";
import { formatDateByLocale } from "../../common/date-functions";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfoIcon from '@mui/icons-material/Info';
import { AddNoteButton, SaveNoteButton, CancelNoteButton, LoadMoreButton } from "./NoteButtons";
import { Chip } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { useApi } from "../../hooks/useApi";

const RESULT_SIZE = 3
const NOTE_MAX_LENGTH = 500

const NotesContent = ({ customerNotes, customerId, location }) => {

  const { currentLanguage } = useContext(LanguageContext);
  const { userInfo } = useAuth();
  const { sendRequest } = useApi();
  const { logEvent } = useMixPanel();

  const addNotePlaceholderTranslation = useTranslateKey("add_note_placeholder");
  const addTranslation = useTranslateKey("add");
  const loadMoreTranslation = useTranslateKey("load_more");
  const addNoteTranslation = useTranslateKey("add_note");
  const saveNoteTranslation = useTranslateKey("save_note");
  const cancelTranslation = useTranslateKey("cancel");

  const [isNoteViewShowing, setNoteViewShowing] = useState(false)
  const [isNoteEditMode, setNoteEditMode] = useState(false)
  const [selectedNote, setSelectedNote] = useState()
  const [notes, setNotes] = useState([])
  const [currentNotesShowing, setCurrentNotesShowing] = useState([])
  const [currentNotesNum, setCurrentNotesNum] = useState(RESULT_SIZE)
  const [loading, setLoading] = useState(true)
  const [noteInput, setNoteInput] = useState(null)
  const [notesMenu, setNotesMenu] = useState(null)

  useEffect(() => {
    if (customerNotes.length > RESULT_SIZE) {
      setCurrentNotesShowing(customerNotes.slice(0, RESULT_SIZE))
    } else {
      setCurrentNotesShowing(customerNotes)
    }
    setNotes(customerNotes)
    setLoading(false)
  }, [customerNotes])

  const loadMoreNotes = () => {
    if (notes.length - currentNotesNum <= RESULT_SIZE) {
      setCurrentNotesShowing(notes)
      setCurrentNotesNum(notes.length)
    } else {
      const newSelection = [...currentNotesShowing, ...notes.slice(currentNotesNum - 1, currentNotesNum + RESULT_SIZE - 1)]
      setCurrentNotesShowing(newSelection)
      setCurrentNotesNum(newSelection.length)
    }
  }

  const reloadNotes = async () => {
    setLoading(true)
    const response = await sendRequest({
      method: "get",
      addPrefix: false,
      endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/notes?customerId=${customerId}`,
    }).catch(() => { });
    const retrievedNotes = response?.data?.items || []
    setNotes(retrievedNotes)
    // after reloading notes go back to showing the first 3
    setCurrentNotesShowing(retrievedNotes.slice(0, RESULT_SIZE));
    setLoading(false)
  }

  const handleNoteMenuClick = (event) => {
    const target = event.currentTarget;
    setNotesMenu(target);
  }

  const handleNoteMenuClose = () => {
    setNotesMenu(null);
    setSelectedNote(null)
  }

  const validateNoteInput = (value) => {
    if (value.length <= NOTE_MAX_LENGTH) {
      setNoteInput(value)
    }
  }

  const handleDeleteNote = async () => {
    logEvent('delete_note', {
      location: location
    })
    setNotesMenu(null)
    setLoading(true)
    const { noteId } = selectedNote
    await sendRequest({
      method: "delete",
      addPrefix: false,
      endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/note`,
      data: JSON.stringify({ noteId }),
      addContentType: "application/json"
    }).catch(() => { });
    reloadNotes();
  }

  const handleEditNote = () => {
    logEvent('edit_note', {
      location: location
    })
    setNoteInput(selectedNote.note)
    setNotesMenu(null)
    setNoteEditMode(true)
    setNoteViewShowing(true)
  }

  const handleAddNote = async () => {
    logEvent('save_new_note', {
      location: location
    })
    setLoading(true)
    const result = await sendRequest({
      method: "put",
      addPrefix: false,
      endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/note/new`,
      data: JSON.stringify({ note: noteInput, customerId: customerId.toString() }),
      addContentType: "application/json"
    }).catch(() => { });
    const newNote = result?.data
    if (newNote) {
      // add new note to the top of the list and keep list to 3
      setCurrentNotesShowing([newNote, ...currentNotesShowing.slice(0, 2)])
      setNotes([newNote, ...notes])
    }
    setNoteInput("")
    setNoteViewShowing(false)
    setLoading(false)
    // todo - show alert
  }

  const handleOverwriteNote = async () => {
    logEvent('save_edited_note', {
      location: location
    })
    setLoading(true)
    const { noteId } = selectedNote
    const result = await sendRequest({
      method: "put",
      addPrefix: false,
      endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/note`,
      data: JSON.stringify({ note: noteInput, noteId }),
      addContentType: "application/json"
    }).catch(() => { });
    const editedNote = result?.data
    if (editedNote) {
      setCurrentNotesShowing(currentNotesShowing.map(n => n.noteId === noteId ? editedNote : n))
    }
    setNoteInput("")
    setNoteViewShowing(false)
    setLoading(false)
  }

  const handleAddNoteButtonClick = () => {
    setNoteEditMode(false)
    setNoteInput("")
    setNoteViewShowing(true)
    logEvent('start_note', {
      location: location
    })
  }

  return loading ? (
    <Stack mt={10} alignItems="center">
      <CircularProgress data-test-id="progress-bar-shown" />
    </Stack>
  ) : (
    <>
      <Stack >
        {!isNoteViewShowing &&
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: "24px" }}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h2"><T>notes</T></Typography>
                &nbsp;
                <Chip
                  className="u-chip"
                  label={<Typography>{notes.length}</Typography>}
                />
              </Stack>
              <AddNoteButton
                id="note-add-button"
                text={addTranslation}
                onClick={handleAddNoteButtonClick}
              />
            </Stack>

            {currentNotesShowing.length > 0 && (
              <>
                <List
                  sx={{
                    width: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    '& ul': { padding: 0 },
                  }}
                >
                  <ul>
                    {currentNotesShowing.map((item, idx) => {
                      return (
                        <Stack key={idx} direction="row" justifyContent="space-between" sx={{
                          padding: "16px",
                          margin: "0 24px 16px 24px",
                          borderRadius: "8px",
                          border: "1px solid #EDEFF1"
                        }}>
                          <Stack>
                            <Stack>
                              <Typography
                                data-test-id="note-item"
                                sx={[styles.noteContent, { wordBreak: 'break-word' }]}
                              >
                                {item.note}
                              </Typography>
                            </Stack>
                            <Stack sx={{ paddingTop: "8px" }} direction="row">
                              <Typography sx={styles.dateText}>
                                {!item.dateEdited && <T>created_on</T>}
                                {!!item.dateEdited && <T>edited_on</T>}
                              </Typography>
                              <Typography sx={[styles.dateText, { marginLeft: "3px" }]}>
                                {formatDateByLocale(currentLanguage, item.dateCreated, DateTime.DATETIME_MED)}
                              </Typography>
                            </Stack>
                          </Stack>
                          <>
                            <MoreVertIcon sx={{ width: "16px", height: "16px", cursor: "pointer", marginLeft: "8px" }}
                              onClick={(event) => {
                                handleNoteMenuClick(event)
                                setSelectedNote(item)
                              }}
                            />
                            <Menu
                              data-test-id="manage-notes-menu"
                              anchorEl={notesMenu}
                              open={!!notesMenu}
                              onClose={handleNoteMenuClose}
                              PaperProps={{
                                style: {
                                  padding: "8px",
                                  borderRadius: "8px",
                                  border: "1px solid rgba(145, 158, 171, 0.1)",
                                  boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)'
                                },
                              }}
                            >
                              <MenuItem
                                data-test-id="manage-notes-menu-edit"
                                onClick={() => handleEditNote()}
                              >
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                  <EditIcon sx={{ marginRight: "12px", width: "16px", height: "16px" }} />
                                  <Typography variant="h2" sx={styles.menuItemStyle}><T>edit_note</T></Typography>
                                </Stack>
                              </MenuItem>
                              <MenuItem
                                data-test-id="manage-notes-menu-remove"
                                onClick={() => handleDeleteNote()}
                                sx={{ marginTop: "2px" }}
                              >
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                  <DeleteOutlineIcon sx={{ marginRight: "12px", width: "16px", height: "16px" }} />
                                  <Typography variant="h2" sx={styles.menuItemStyle}><T>remove_note</T></Typography>
                                </Stack>
                              </MenuItem>
                            </Menu>
                          </>
                        </Stack>
                      )
                    })}
                  </ul>
                </List>
                {
                  notes.length > currentNotesShowing.length && (
                    <Stack direction="row" justifyContent={"center"} sx={{ marginBottom: "24px" }}>
                      <LoadMoreButton
                        id="notes-load-more-button"
                        text={loadMoreTranslation}
                        onClick={() => loadMoreNotes()}
                      />
                    </Stack>
                  )
                }
              </>
            )}
            {currentNotesShowing.length === 0 && (
              <Stack direction="row" justifyContent={"center"} sx={{ padding: "24px" }}>
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ padding: "12px", background: "rgba(145, 158, 171, 0.06)", borderRadius: "8px" }}>
                  <InfoIcon sx={{ marginRight: "12px", width: "16px", height: "16px", color: "rgba(21, 56, 98, 0.7)" }} />
                  <Typography data-test-id="note-no-notes" sx={styles.noteContent}><T>no_notes_to_display</T></Typography>
                </Stack>
              </Stack>
            )}
          </>
        }

        {!!isNoteViewShowing &&
          <Stack sx={{ padding: "24px" }}>
            {!isNoteEditMode && <Typography variant="h2"><T>add_new_note</T></Typography>}
            {isNoteEditMode && <Typography variant="h2"><T>edit_note</T></Typography>}
            <Stack sx={{ marginTop: "36px", marginBottom: "24px" }}>
              <TextField
                data-test-id="note-add-textfield"
                label="Note"
                multiline
                rows={8}
                placeholder={addNotePlaceholderTranslation}
                InputProps={{
                  style: styles.textFieldStyle
                }}
                InputLabelProps={{
                  style: styles.textFieldStyle
                }}
                value={noteInput}
                onChange={event => validateNoteInput(event.target.value)}
              />
              <Typography sx={[styles.dateText, { alignSelf: "flex-end", marginTop: "4px", marginRight: "16px" }]}>
                {noteInput.length}/{NOTE_MAX_LENGTH}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" mb={1} gap={1}>
              <CancelNoteButton
                id="add-note-button"
                text={cancelTranslation}
                onClick={() => setNoteViewShowing(false)}
              />
              <SaveNoteButton
                id="add-note-button"
                text={isNoteEditMode ? saveNoteTranslation : addNoteTranslation}
                onClick={() => isNoteEditMode ? handleOverwriteNote() : handleAddNote()}
                disabled={isNoteEditMode ? noteInput === selectedNote.note : noteInput.length === 0}
              />
            </Stack>
          </Stack>
        }
      </Stack>
      <Divider />
    </>
  )
}

const styles = {
  textFieldStyle: {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "12px"
  },
  menuItemStyle: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "12px"
  },
  noteContent: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "12px"
  },
  dateText: {
    fontWeight: "500",
    fontSize: "10px",
    opacity: ".6",
    lineHeight: "12px"
  }
}

export { NotesContent };
import { useTranslateKey } from "../../hooks/useTranslate";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment-timezone';

import { Box, Typography, CircularProgress, Divider } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from "@mui/icons-material/Close";

import { DrawerButton } from "./DrawerButton";

import { OfficeAvatar } from "../OfficeAvatar";
import { CopyToClipboard } from "../CopyToClipboard";
import { MemberErrorScreen } from "../../screens/ErrorScreen/MemberErrorScreen";
import { CustomerTypeChip } from "../CustomerTypeChip"

import { useAuth } from "../../hooks/useAuth";
import { isDateInFuture } from "../../common/date-functions";

import {
  subscriptionDate,
  officeAvatarWrapper,
} from "./drawer-styles";
import { formatSubscriptionDate } from "../../common/format-functions";

import { MemberContext } from "../../hooks/MemberContext";

import { ProfilePhoneLine } from "./ProfilePhoneLine";
import { ProfileEmailLine } from "./ProfileEmailLine";
import { ComButtons } from "../../components/drawer/ComButtons";
import { ProfileTimezoneLine } from "./ProfileTimezoneLine.tsx";
import { ProfileLocationLine } from "./ProfileLocationLine";
import { ProfileReferralLinkLine } from "./ProfileReferralLinkLine";

import { NotesContent } from "../notes/NotesContent";
import { useMixPanel } from "../../hooks/useMixPanel";
import { LanguageContext } from "../../hooks/LanguageContext";
import { useTheme } from "@mui/material/styles";

const MemberProfileDrawerContent = ({ isWebView }) => {
  const { userInfo } = useAuth();
  const { logEvent } = useMixPanel();
  const theme = useTheme();

  const {
    memberData,
    customerHrefData,
    apiChainInProgress,
    customerOrdersLength,
    customerReferrals,
    customerNotes,
    errorMessage,
  } = useContext(MemberContext);
  const { languageDir } = useContext(LanguageContext);
  const location = useLocation();
  const navigate = useNavigate();

  const showSubscriptionChip = false;
  const showCustomerTypeChip = ["Customer", "WholesaleCustomer", "PreferredCustomer"].includes(customerHrefData.type);

  const translatedOrders = useTranslateKey("orders");
  const translatedReferrals = useTranslateKey("referrals");

  const profilePictureUrl = customerHrefData.profilePicture?.sizes[0]?.media;
  const isFutureDate = isDateInFuture(memberData.subscriptions?.at(-1)?.endDate)

  const typographyProps = !!isFutureDate
    ? {
      "data-test-id": "subscription-status",
      sx: { ...subscriptionDate, display: "flex" },
      style: {
        color: theme.subscription.subscribed.color,
        backgroundColor: theme.subscription.subscribed.backgroundColor,
      }
    }
    : {
      "data-test-id": "subscription-status",
      sx: { ...subscriptionDate },
      style: {
        color: "rgb(127, 145, 168)",
        backgroundColor: "rgb(232, 235, 239)",
      }
    }

  // set boolean based on whether the sponsor for a given member is the current user/distributor
  const showReferrer = () => {
    return customerHrefData.sponsor.id.unicity !== userInfo.unicityId;
  };

  const handleReferrerClick = () => {
    logEvent("open_profile", {
      location: "member_profile_referred_by",
      user_type: "member"
    })
    navigate(`${location.pathname}?drawer=member&id=${customerHrefData.sponsor.id.unicity}&drawerContent=profile`, { state: location });
  };

  const handleArrowClick = () => {
    navigate(-1, { replace: true });
  };

  // if error in any of the three API calls, render MemberErrorScreen with message
  if (errorMessage) {
    return <MemberErrorScreen />;
  }

  // render loading bar while API chain in in progress
  if (apiChainInProgress)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 500,
        }}
      >
        <CircularProgress data-test-id="progress-bar-shown" />
      </Box>
    );

  // Add timezone info to customerHrefData if not present
  if (customerHrefData && !customerHrefData.timezone) {
    customerHrefData.timezone = moment.tz.guess(); // Gets user's local timezone
  }

  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <Box sx={{
          display: "flex",
          justifyContent: isWebView ? "flex-end" : "space-between",
          mb: 3
        }}>
          {!isWebView && (languageDir === "ltr" ?
            <KeyboardBackspaceIcon
              onClick={handleArrowClick}
              sx={{ cursor: "pointer" }}
            /> :
            <ArrowForwardIcon
              onClick={handleArrowClick}
              sx={{ cursor: "pointer" }}
            />)}
          <CloseIcon onClick={handleArrowClick} sx={{ cursor: "pointer" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Box sx={officeAvatarWrapper}>
            <OfficeAvatar
              id="user-avatar"
              imageUrl={profilePictureUrl}
              height={40}
              width={40}
              firstInitial={customerHrefData.humanName.firstName[0]}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              data-test-id="member-profile-name"
              sx={{
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              {customerHrefData.humanName.firstName}{" "}
              {customerHrefData.humanName.lastName}
            </Typography>
            <Typography
              sx={{
                fontSize: 10,
                color: "#7388A1",
              }}
            >
              <span data-test-id="member-profile-uid">
                UID: {customerHrefData.id.unicity}{" "}
              </span>
              <CopyToClipboard
                stringToCopy={`${customerHrefData.id.unicity}`}
                iconId="copyUnicityId"
              />
              {showReferrer() ? (
                <Box component={"span"}>
                  <span data-test-id="member-profile-sponsor">{`• Referred by `}</span>
                  <span
                    onClick={handleReferrerClick}
                    style={{ cursor: "pointer" }}
                  >{`${customerHrefData.sponsor.humanName.firstName} ${customerHrefData.sponsor.humanName.lastName}`}</span>
                </Box>
              ) : (
                ""
              )}
            </Typography>
          </Box>
        </Box>
        {
          // show CustomerTypeChip for neccesary customer types
          // https://app.clickup.com/t/863hbdtmt
          showCustomerTypeChip &&
          <Box display="flex" flexDirection="column" alignItems="flex-start" paddingLeft="52px">
            <CustomerTypeChip
              customerType={customerHrefData.type}
              customStyles={{
                fontSize: "10px",
                padding: "4px 8px"
              }}
            />
            {showSubscriptionChip &&
              (memberData.subscriptions) &&
              <Typography
                {...typographyProps}
              >
                {
                  isDateInFuture
                    ? `Subscription Renewal: ${formatSubscriptionDate(memberData.subscriptions.at(-1).endDate)}`
                    : "No subscription"
                }
              </Typography>
            }
          </Box>
        }
        <ComButtons
          customerHrefData={customerHrefData}
          drawerType="member"
          eventLocation={"member_profile"}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 2,
          }}
        >
          {/* phone section */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <ProfilePhoneLine
              customerHrefData={customerHrefData}
              drawerType="member"
              eventLocation="member_profile"
            />
          </Box>

          {/* email section */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <ProfileEmailLine
              customerHrefData={customerHrefData}
              drawerType="member"
              eventLocation="member_profile"
            />
          </Box>

          {/* referral link section */}
          <Box sx={{ display: 'flex', gap: 1.7 }}>
            <ProfileReferralLinkLine
              customerHrefData={customerHrefData}
              referralCode={memberData?.referralCode}
              drawerType="member"
              eventLocation="member_profile"
            />
          </Box>

          <ProfileLocationLine 
            customerHrefData={customerHrefData} 
            drawerType="distributor"
          />

          <ProfileTimezoneLine 
            customerHrefData={customerHrefData} 
            drawerType="member" 
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <DrawerButton
            id="member-profile-order-button"
            text={`${translatedOrders} (${customerOrdersLength < 10 ? customerOrdersLength : "10+"})`}
            isDisabled={customerOrdersLength === 0}
            type="orders"
          />
          <DrawerButton
            id="member-profile-referral-button"
            text={`${translatedReferrals} (${customerReferrals?.length})`}
            isDisabled={customerReferrals?.length === 0}
            type="referrals"
          />
        </Box>
      </Box>
      <Divider />
      <NotesContent
        customerNotes={customerNotes}
        customerId={memberData.unicity}
        location="member_profile"
      />
    </>
  );
};

export { MemberProfileDrawerContent };

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../hooks/useAuth";
import { BusinessNavItem } from "./BusinessNavItem";
import { ResourceNavItem } from "./ResourceNavItem";
import { businessDrawerItems, resourceDrawerItems } from "./nav-drawer-items";
import { CopyrightSection } from "../CopyrightSection";
import { ReactComponent as UnicityLogo } from '../../assets/icons/logo-unicity.svg';
import { ReactComponent as LogoOfficeIcon } from '../../assets/icons/logo-office.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { marketsWithLibrary, marketsWithNewLibrary } from "../../common/marketExceptions";

const MobileNavDrawer = ({ drawerOpen, toggleDrawer, shopLink }) => {
  const { userInfo } = useAuth();
  const [drawerWidth, setDrawerWidth] = useState(240);

  const subheaderStyle = {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 10,
    color: "#8A9BB0",
    mb: 2,
    mt: 2,
    ml: 1,
  };

  return (
    <div>
      <Drawer open={drawerOpen} onClose={toggleDrawer}>
        <Box
          id="nav-drawer"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: drawerWidth,
            height: "100vh",
            pl: "16px",
            pr: "16px",
            pt: "26px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                mb: "42px",
                alignItems: "center",
              }}
            >
              <Box
                onClick={toggleDrawer}
                aria-label="close navigation"
                sx={{ cursor: "pointer" }}
              >
                <CloseIcon className="close-icon" />
              </Box>
              <Box sx={{ display: "flex", gap: "6.5px", alignItems: "center" }}>
                <UnicityLogo className="unicity-logo" aria-label="Unicity Logo" style={{ width: "87px" }} />
                <LogoOfficeIcon className="unicity-logo-office" />
              </Box>
            </Box>
            <Box>
              <Typography sx={subheaderStyle}>BUSINESS</Typography>
            </Box>
            <Box>
              {businessDrawerItems.map((item, index) => {
                return (
                  <BusinessNavItem
                    key={index}
                    item={item}
                    drawerOpen={drawerOpen}
                    toggleDrawer={toggleDrawer}
                    isMobile={true}
                  />
                );
              })}
            </Box>
            <Box>
              <Typography sx={subheaderStyle}>RESOURCES</Typography>
            </Box>
            {resourceDrawerItems
              .map((item, index) => {
                if (item.value !== "new-library" && item.value !== "old-library") {
                  return (
                    <ResourceNavItem
                      key={index}
                      item={item}
                      drawerOpen={drawerOpen}
                      isMobile={true}
                      setDrawerWidth={setDrawerWidth}
                      shopLink={shopLink}
                    />
                  )
                }
                if (item.value === "old-library" && !marketsWithNewLibrary.includes(userInfo.countryCode) && marketsWithLibrary.includes(userInfo.countryCode)) {
                  return (
                    <ResourceNavItem
                      key={index}
                      item={item}
                      drawerOpen={drawerOpen}
                      isMobile={true}
                      setDrawerWidth={setDrawerWidth}
                      shopLink={shopLink}
                    />
                  )
                }
                if (item.value === "new-library" && marketsWithNewLibrary.includes(userInfo.countryCode)) {
                  return (
                    <ResourceNavItem
                      key={index}
                      item={item}
                      drawerOpen={drawerOpen}
                      isMobile={true}
                      setDrawerWidth={setDrawerWidth}
                      shopLink={shopLink}
                    />
                  )
                }
                return null;
              })}
          </Box>
          <Box>
            <CopyrightSection padding={1.5} />
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export { MobileNavDrawer };

import React from 'react';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';

import useMediaQuery from "@mui/material/useMediaQuery";

const StyledSettingsMenu = styled((props) => {
  return (
    <Menu
      data-test-id={`styled-settings-menu`}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  );
})(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: "10px",
    minWidth: "250px",
    width: !useMediaQuery(theme.breakpoints.up("sm")) && "80%",
    border: "1px solid rgba(145, 158, 171, 0.10)",
    borderRadius: 8,
    padding: "16px 24px 16px 24px",
    filter: "drop-shadow(0px 1px 3px rgba(145, 158, 171, 0.10)) drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.08))",
    '& .MuiMenu-list': {
      padding: "14px 0px 0px 0px"
    },
    '& .MuiButtonBase-root': {
      fontSize: "12px",
      fontWeight: "600"
    },
    '& .MuiMenuItem-root': {
      padding: "4px 0px 4px 0px",
      '&:hover': {
        backgroundColor: "#FAFAFA",
      },
    },
  },
}));

export { StyledSettingsMenu };

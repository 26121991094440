import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const SubheaderBox = styled(Box)(({ theme }) => ({
  fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 10,
    color: "#8A9BB0",
    marginTop: "8px",
    marginBottom: "8px",
    marginLeft: "15px"
}));

export default SubheaderBox;
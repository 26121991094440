import React from "react";
import { T } from "../hooks/useTranslate";

import { Typography, Chip } from "@mui/material";

const CustomerTypeChip = ({ customerType, customStyles }) => {  
  // map customerType value from API to appropriate rosetta translation key
  // https://app.clickup.com/t/863hbdtmt?comment=90100022842726&threadedComment=90020046154772
  const mapToKey = customerType === "Member" ? "member" :
    customerType === "Customer" ? "retail" :
      customerType === "WholesaleCustomer" ? "wholesale" :
        customerType === "PreferredCustomer" ? "preferred"
          : "Customer Type Not Found";

  return (
    <Chip
      className="u-chip"
      variant={`type-${mapToKey}`}
      data-test-id={`customer-type-chip-${mapToKey}`}
      sx={customStyles}
      label={<Typography variant="h9"><T>{mapToKey}</T></Typography>}      
    />
  );
}

export { CustomerTypeChip };

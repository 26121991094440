import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { T, TElement } from '../../hooks/useTranslate';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import moment from 'moment-timezone';

import {
  iconSizingStyle,
  gridTextStyle
} from './drawer-styles';

interface ProfileTimezoneLineProps {
  customerHrefData: {
    timezone?: string;
    humanName?: {
      firstName?: string;
      lastName?: string;
    };
  };
  drawerType: string;
}

export function ProfileTimezoneLine({ customerHrefData, drawerType }: ProfileTimezoneLineProps) {
  const currentTime = customerHrefData.timezone
    ? moment().tz(customerHrefData.timezone).format('h:mm A')
    : null;

  const customerName = `${customerHrefData.humanName?.firstName} ${customerHrefData.humanName?.lastName}`;

  return (
    <Stack direction="row" gap={1}>
      <AccessTimeFilledIcon sx={iconSizingStyle} />
      {customerHrefData.timezone ? (
        <Typography
          sx={{
            ...gridTextStyle,
            marginLeft: 1.2
          }}
          data-testid={`${drawerType}-profile-timezone`}
        >
          <TElement
            isUpperCase={false}
            isTitleCase={false}
            param0={<Box component="span" sx={{ fontWeight: 600 }}>{currentTime}</Box>}
            param1={customerName}
          >
            its_time_for
          </TElement>
        </Typography>
      ) : (
        <Typography
          sx={gridTextStyle}
          data-testid={`${drawerType}-profile-timezone`}
        >
          <T isUpperCase={false} isTitleCase={false}>not_available</T>
        </Typography>
      )}
    </Stack>
  );
}
import { useRef, useState, useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { Chip, Divider, Stack, Typography } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";

import { OfficeAvatar } from "./../OfficeAvatar";
import { CopyToClipboard } from "../../components/CopyToClipboard";

import { SettingsMenuItem } from "./SettingsMenuItem";
import { StyledSettingsMenu } from "./StyledSettingsMenu";
import { SettingsButton } from "./SettingsButton";
import "./SettingsMenu.css";

import { LanguageModal } from "./LanguageModal";
import { LanguageModalMobile } from "./LanguageModalMobile";
import { LanguageContext } from "../../hooks/LanguageContext";

import { RANK_DETAILS } from "../../common/rankDetails";
import { LANGUAGE_DETAILS } from "../../common/languageDetails";
import { toTitleCase } from "../../common/format-functions";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useMixPanel } from "../../hooks/useMixPanel";

import { getCountryInfo } from "../../utils/countryCodeConverter";
import React from "react";

import { useFlags } from 'launchdarkly-react-client-sdk';

const SettingsMenu = () => {
  const menuAnchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const { logEvent } = useMixPanel();

  const menuIsOpen = Boolean(anchorEl);

  const openMenu = () => setAnchorEl(menuAnchorRef.current);
  const handleClose = () => setAnchorEl(null);

  // desktop modal state
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  // mobile modal state
  const [openMobile, setOpenMobile] = useState(false);
  const handleMobileModalOpen = () => setOpenMobile(true);
  const handleMobileModalClose = () => setOpenMobile(false);

  const { userInfo, logout } = useAuth();
  const { currentLanguage } = useContext(LanguageContext);
  const rankDetails = RANK_DETAILS.filter(
    (rank) => rank.rankCode === userInfo.highestRank
  )[0];

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const { unicityDomains } = useFlags();

  const getSubscriptionsDomain = () => {
    const countryInfo = getCountryInfo(userInfo?.countryCode);
    if (unicityDomains && countryInfo) {
      const alpha2Code = countryInfo.alpha2;

      if (unicityDomains.countries.includes(alpha2Code)) {
        const alpha3Code = countryInfo.alpha3.toLowerCase();
        return unicityDomains.REACT_APP_AR_DOMAIN
          .replace("{country}", alpha3Code)
          .replace("{lang}", currentLanguage);
      }
    }

    return process.env.REACT_APP_AR_PROD_DOMAIN;
  };

  return (
    <>
      <SettingsButton
        rankDetails={rankDetails}
        userInfo={userInfo}
        menuIsOpen={menuIsOpen}
        viewportIsSmall={viewportIsSmall}
        openMenu={openMenu}
        menuAnchorRef={menuAnchorRef}
      />
      <StyledSettingsMenu
        id="settingsMenu"
        anchorEl={anchorEl}
        open={menuIsOpen}
        onClose={handleClose}
        mobile={!viewportIsSmall ? "true" : "false"}
        anchorReference={viewportIsSmall ? "anchorEl" : "none"}
        sx={{
          // styles needed for parent of unanchored menu
          display: !viewportIsSmall && "flex",
          marginTop: !viewportIsSmall && "70px",
          justifyContent: !viewportIsSmall && "center",
        }}
      >
        <Stack alignItems="center" gap={2} marginBottom="32px">
          <OfficeAvatar
            id="settingsAvatar"
            imageUrl={userInfo.profilePictureUrl}
            firstInitial={userInfo.name ? userInfo.name[0] : ""}
            height={60}
            width={60}
            showBorder={true}
            borderColor={rankDetails?.color}
          />
          <Stack alignItems={"center"}>
            <Typography variant="h3" marginBottom="6px">
              {userInfo.name}
            </Typography>
            <Stack direction="row" alignItems="center" gap="4px">
              <Typography variant="h10">UID: {userInfo.unicityId}</Typography>
              <CopyToClipboard
                stringToCopy={userInfo.unicityId}
                sx={{
                  color: "#153862",
                  opacity: ".6",
                  cursor: "pointer",
                }}
              />
            </Stack>
          </Stack>
          {rankDetails?.rankTranslateFullKey && (
            <Chip
              className="u-chip"
              variant={`rank-${rankDetails?.rankId}`}
              label={<Typography variant="h9"><T>{rankDetails?.rankTranslateFullKey}</T></Typography>}
            />
          )}
        </Stack>
        <Divider />
        <Stack className="settings-menu-item-container" gap="12px" mt={1.5}>
          <SettingsMenuItem
            id="order-history"
            icon="history"
            rightIcon="external"
            text1="order_history"
            testId="orderHistoryMenuItem"
            currentLanguage={currentLanguage}
            customHref={process.env.REACT_APP_IS_PRODUCTION === "false"
              ? process.env.REACT_APP_OH_QA_DOMAIN
              : process.env.REACT_APP_OH_PROD_DOMAIN
            }
            clickHandler={() =>
              logEvent("order_history", { location: "secondary_menu" })
            }
          />
          <SettingsMenuItem
            id="ar-order"
            icon="shopping-bag"
            rightIcon="external"
            text1="subscriptions"
            testId="autoRefillMenuItem"
            currentLanguage={currentLanguage}
            customHref={getSubscriptionsDomain()}
            clickHandler={() =>
              logEvent("ar-shop", { location: "secondary_menu" })
            }
          />
          <SettingsMenuItem
            id="provide-feedback"
            icon="feedback"
            text1="provide_feedback"
            testId="provideFeedbackMenuItem"
            useHref={true}
            currentLanguage={currentLanguage}
            urlKey={"provide_feedback_url"}
            clickHandler={() =>
              logEvent("provide_feedback_view", { location: "secondary_menu" })
            }
          />
          <SettingsMenuItem
            id="help-center"
            icon="external-help"
            text1="help_center"
            testId="helpCenterMenuItem"
            useHref={true}
            currentLanguage={currentLanguage}
            urlKey={"distributor_help_center_url"}
            clickHandler={() =>
              logEvent("help_center_view", { location: "secondary_menu" })
            }
          />
          <SettingsMenuItem
            id="language"
            clickHandler={
              viewportIsSmall ? handleModalOpen : handleMobileModalOpen
            }
            icon="globe"
            text1="language"
            text2={toTitleCase(
              LANGUAGE_DETAILS.filter(
                (language) => language.i18nCode === currentLanguage
              )[0].label
            )}
            testId="language-option-menu-item"
            noRedirect={true}
          />
          <Divider />
          <SettingsMenuItem
            id="sign-out"
            clickHandler={logout}
            icon="signout"
            text1="sign_out"
            isLogout={true}
            testId="logOutMenuItem"
            noRedirect={true}
          />
        </Stack>
      </StyledSettingsMenu>
      <LanguageModal
        open={open}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        handleClose={handleClose}
      />
      <LanguageModalMobile
        open={openMobile}
        handleModalOpen={handleMobileModalOpen}
        handleModalClose={handleMobileModalClose}
        handleClose={handleClose}
      />
    </>
  );
};

export { SettingsMenu };

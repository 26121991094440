import { Stack, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { OfficeBox } from "../OfficeBox";

export const DashboardSkeleton = () => {
  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Stack
        direction={viewportIsLarge ? "row" : "column"}
        gap={viewportIsLarge ? "18px" : "20px"}
        alignItems={viewportIsLarge ? "flex-start" : "stretch"}
        data-test-id="dashboard-skeleton"
      >
        <Stack sx={{ flex: "1 1 100%" }} gap="20px">
          {/* Global Notice Skeleton - Only show on non-large viewports */}
          {!viewportIsLarge && (
            <OfficeBox innerRef={null}>
              <Skeleton variant="rectangular" height={60} />
            </OfficeBox>
          )}

          {/* Rank Qualifications Skeleton */}
          <OfficeBox sx={{ borderRadius: '12px', padding: '12px' }} innerRef={null}>
            <Skeleton variant="text" width="60%" height={32} />
            <Stack flexDirection="row" gap={2} mt={1}>
              <Skeleton
                variant="rectangular"
                width="50%"
                height={40}
                sx={{ borderRadius: '4px' }}
              />
              <Skeleton
                variant="rectangular"
                width="50%"
                height={40}
                sx={{ borderRadius: '4px' }}
              />
            </Stack>
            <Stack gap={2} mt={2}>
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  sx={{ borderRadius: '8px' }}
                  variant="rectangular"
                  height={60}
                />
              ))}
            </Stack>
            <Stack gap={2} mt={3}>
              {[...Array(2)].map((_, index) => (
                <Skeleton
                  key={index}
                  sx={{ borderRadius: '8px' }}
                  variant="rectangular"
                  height={70}
                />
              ))}
            </Stack>
            <Stack gap={2} mt={1} flexDirection="row">
              {[...Array(2)].map((_, index) => (
                <Skeleton
                  key={index}
                  sx={{ borderRadius: '8px' }}
                  variant="rectangular"
                  height={70}
                  width="50%"
                />
              ))}
            </Stack>
          </OfficeBox>

          {/* Stats Content - Show on large viewport */}
          {viewportIsLarge && (
            <OfficeBox innerRef={null} sx={{ padding: '20px' }}>
              <Skeleton variant="text" width="40%" height={32} />
              <Skeleton variant="rectangular" sx={{ mt: 1 }} height={70} width="100%" />
            </OfficeBox>
          )}
        </Stack>

        <Stack sx={{ flex: "1 1 100%" }} gap="20px">
          {/* Centurion Qualifications Skeleton */}
          <OfficeBox innerRef={null} sx={{ borderRadius: '12px', padding: '12px' }}>
            <Skeleton variant="text" width="50%" height={32} />
            <Stack gap={2} mt={2}>
              <Skeleton sx={{ borderRadius: '8px' }} variant="rectangular" height={110} />
            </Stack>
          </OfficeBox>
        </Stack>

        {/* Stats Content - Show on non-large viewport */}
        {!viewportIsLarge && (
          <Stack sx={{ flex: "1 1 100%" }}>
            <OfficeBox innerRef={null} sx={{ padding: '20px' }}>
              <Skeleton variant="text" width="40%" height={32} />
              <Skeleton variant="rectangular" sx={{ mt: 1 }} height={70} width="100%" />
            </OfficeBox>
          </Stack>
        )}

        <Stack gap="20px" sx={{ flex: "1 1 100%" }}>
          {/* News Content Skeleton */}
          <OfficeBox innerRef={null} sx={{ borderRadius: '12px', padding: '12px' }}>
            <Skeleton variant="text" width="30%" height={32} />
            <Stack gap={2} mt={2}>
              {[...Array(2)].map((_, index) => (
                <Skeleton key={index} variant="rectangular" height={60} />
              ))}
            </Stack>
          </OfficeBox>

          {/* Recent Activity Skeleton */}
          <OfficeBox innerRef={null} sx={{ borderRadius: '12px', padding: '12px' }}>
            <Skeleton variant="text" width="45%" height={32} />
            <Stack gap={2} mt={2}>
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} variant="rectangular" height={60} />
              ))}
            </Stack>
          </OfficeBox>

          {/* Feel Great Link Skeleton */}
          <OfficeBox innerRef={null} sx={{ paddingTop: '20px', borderRadius: '12px', padding: '12px' }}>
            <Skeleton variant="rectangular" height={100} />
          </OfficeBox>
        </Stack>
      </Stack>

      {/* Explained Button Skeleton */}
      <Stack alignItems="center" mt={viewportIsLarge ? 10 : 4} mb={viewportIsLarge ? 10 : 4}>
        <Skeleton sx={{ borderRadius: '4px' }} variant="rectangular" width={200} height={48} data-test-id="dashboard-explained-button" />
      </Stack>
    </>
  );
};

export default DashboardSkeleton; 
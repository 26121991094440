import React from 'react';
import { Box, Grid, Skeleton, Stack, useTheme, useMediaQuery } from '@mui/material';

export const LibraryScreenSkeleton: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const FeaturedCardSkeleton = () => (
    <Skeleton
      variant="rectangular"
      sx={{
        width: { xs: '320px', sm: '320px' },
        height: '200px',
        borderRadius: "12px",
      }}
      data-test-id="featured-card-skeleton"
    />
  );

  const DocumentCardSkeleton = () => (
    <Box sx={{ width: '100%' }}>
      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: { xs: '180px', sm: '200px' },
          borderRadius: "12px",
          mb: 1
        }}
        data-test-id="document-card-skeleton"
      />
      <Skeleton width="70%" height={24} sx={{ mb: 0.5 }} />
      <Skeleton width="40%" height={20} />
    </Box>
  );

  return (
    <Box sx={{ width: '100%' }} data-test-id="library-screen-skeleton">
      {/* Search and Language Section */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ px: { xs: '16px', sm: '0' }, mb: 3 }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            width: '100%',
            maxWidth: '600px',
            height: 56,
            borderRadius: 1
          }}
          data-test-id="search-skeleton"
        />
        <Skeleton
          variant="rectangular"
          sx={{
            width: '200px',
            height: 56,
            borderRadius: 1,
            display: { xs: 'none', sm: 'block' }
          }}
          data-test-id="language-selector-skeleton"
        />
      </Stack>

      {/* Welcome Message Section */}
      <Box sx={{ px: { xs: 2, sm: 4 }, mb: 4 }}>
        <Skeleton
          variant="text"
          sx={{
            width: '60%',
            height: 40,
            mb: 2
          }}
          data-test-id="welcome-message-skeleton"
        />
      </Box>

      {/* Featured Section */}
      <Box sx={{ px: { xs: 2, sm: 4 }, mb: 4 }}>
        <Grid
          container
          spacing={3}
          sx={{
            flexWrap: { xs: 'nowrap', sm: 'wrap' },
            overflow: { xs: 'auto', sm: 'visible' },
            justifyContent: { xs: 'flex-start', sm: 'center' },
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            scrollbarWidth: 'none',
          }}
        >
          {[...Array(3)].map((_, index) => (
            <Grid item key={index}>
              <FeaturedCardSkeleton />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Category Tabs - Desktop Only */}
      {!isMobile && (
        <Box sx={{ mb: 4, display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
          <Skeleton
            variant="rectangular"
            sx={{
              width: '600px',
              height: 48,
              borderRadius: 1
            }}
            data-test-id="category-tabs-skeleton"
          />
        </Box>
      )}

      {/* Document Cards Grid */}
      {isMobile ? (
        // Mobile Layout
        <Box sx={{ px: 2 }}>
          {[...Array(2)].map((_, sectionIndex) => (
            <Box key={sectionIndex} sx={{ mb: 4 }}>
              <Skeleton width="40%" height={32} sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                {[...Array(4)].map((_, index) => (
                  <Grid item xs={6} key={index}>
                    <DocumentCardSkeleton />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Box>
      ) : (
        // Desktop Layout
        <Box sx={{ px: 4 }}>
          <Grid container spacing={3}>
            {[...Array(9)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <DocumentCardSkeleton />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default LibraryScreenSkeleton; 
import React, { Suspense } from 'react';
import { Box, Skeleton } from '@mui/material';

const Flag = React.lazy(() => import('react-world-flags'));

const LazyFlag = ({ code, ...props }) => {
  return (
    <Suspense fallback={<Skeleton variant="rectangular" width={props.width || 24} height={props.height || 16} />}>
      <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Flag code={code} {...props} />
      </Box>
    </Suspense>
  );
};

export default LazyFlag; 